import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.scss'
import WeekCreate from './components/admin_components/WeekCreate.component'
import AdminPage from './components/admin_components/AdminPage.component'
import EmployeesPage from './components/admin_components/EmployeesPage.component'
import Manager from './components/admin_components/Manager.compoent'
import AdminLogin from './components/admin_components/AdminLogin.component'
import AlbumManage from './components/admin_components/AlbumManage.component'
import BlogManage from './components/admin_components/BlogManage.component'
import RedirectManager from './components/minor_components/Redirects.component'

import LandingPage from './components/webfront_components/LandingPage.component'
import LadiesPage from './components/webfront_components/LadiesPage.component'
import ProfilePage from './components/webfront_components/ProfilePage.component'
import SchedulePage from './components/webfront_components/SchedulePage.component'
import MobileNav from './components/minor_components/MobileNav.component'
import RatesPage from './components/webfront_components/RatesPage.component'
import DiscretionPage from './components/webfront_components/DiscretionPage.component'
import TourPage from './components/webfront_components/TourPage.component'
import NotFound from './components/webfront_components/NotFound.component'
import BlogSingle from './components/webfront_components/BlogSingle.component'
import PrivPolicy from './components/webfront_components/PrivPolicy.component'
import BlogPage from './components/webfront_components/BlogPage.component'
import ContactPage from './components/webfront_components/ContactPage.component'

function App() {

	const [screenSize , setScreenSize] = useState(null)

	useEffect(() => {
		setScreenSize(window.innerWidth)
	}, [])

	return (
		<div id="app-container" >
			<Router>
				<Switch>
					<Route path="/" exact component={LandingPage} />
					<Route path="/viper-room-ladies" exact component={LadiesPage} />
					<Route path="/viper-room-ladies/:name" exact component={ProfilePage} />
					<Route path="/schedule" exact component={SchedulePage} />
					<Route path="/our-services" exact component={RatesPage} />
					<Route path="/discretion" exact component={DiscretionPage} />
					<Route path="/tour" exact component={TourPage} />
					<Route path="/privacy-policy" exact component={PrivPolicy} />
					<Route path="/whats-happening" exact component={BlogPage} />
					<Route path="/blog/:title" exact component={BlogSingle} />
					<Route path="/contact" exact component={ContactPage} />

					<Route path="/admin" exact component={AdminLogin} />
					<Route path="/dashboard" exact component={AdminPage} />
					<Route path="/create-week" exact component={WeekCreate} />
					<Route path="/employees" exact component={EmployeesPage} />
					<Route path="/week/:date" exact component={Manager} />
					<Route path="/manage-album" exact component={AlbumManage} />
					<Route path="/manage-blog" exact component={BlogManage} />
					
					<Route path="/schedule-2" exact component={() => <RedirectManager to="schedule"/>}/>

					<Route component={NotFound} />
				</Switch>
				{screenSize < 750 ? <MobileNav /> : null}  
			</Router>
			<div 
				id="back-top-btn" 
				style={screenSize < 750 ?
					{"bottom": "17vh", "right": "6vw"}
					:
					{"bottom": "50px", "right": "50px"}
				}
				onClick={() => {
					window.scrollTo(0, 0)
				}}>
					
				<i class="fas fa-angle-up"></i>
			</div>
		</div>
	);
}

export default App;
