import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import PageHeader from '../minor_components/PageHeader.component'
import avatarDefault from '../../images/default-pic.png'
import PageFooter from '../minor_components/PageFooter.component'
import loadingGif from '../../images/loading.gif'
import moment from 'moment'
import { Helmet } from 'react-helmet'

const SchedulePage = (props) => {


    const [updateWeek, promptUpdateWeek ] = useState(false)
    const [loading, setLoading] = useState(true)
    const [workforce, setWorkForce] = useState([])
    const [updateEmployees, setUpdateEmp ] = useState(false)
    const [hovering, setHovering] = useState(null)
    const [mouseX, setMouseX] = useState(null)
    const [mouseY, setMouseY] = useState(null)
    const [screenSize , setScreenSize] = useState(null)
    const [weekData, setWeekData] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
        setScreenSize(window.innerWidth)
    }, [])
      
    // use effects for rendering updates to week data (if search querying)
    useEffect(() => {        
        getApiWeekData()
    },[updateWeek])

    useEffect(() => {
        getApiEmployees()
    }, [updateEmployees])

    // api call for next seven days shift data
    const getApiWeekData = () => {
        let calls = []
        const pastSeven = [
            moment().subtract(6, 'day').format("DD/MM/YYYY"),
            moment().subtract(5, 'day').format("DD/MM/YYYY"),
            moment().subtract(4, 'day').format("DD/MM/YYYY"),
            moment().subtract(3, 'day').format("DD/MM/YYYY"),
            moment().subtract(2, 'day').format("DD/MM/YYYY"),
            moment().subtract(1, 'day').format("DD/MM/YYYY"),
        ]
        const nextSeven = [
            moment().format("DD/MM/YYYY"),
            moment().add(1, 'day').format("DD/MM/YYYY"),
            moment().add(2, 'days').format("DD/MM/YYYY"),
            moment().add(3, 'days').format("DD/MM/YYYY"),
            moment().add(4, 'days').format("DD/MM/YYYY"),
            moment().add(5, 'days').format("DD/MM/YYYY"),
            moment().add(6, 'days').format("DD/MM/YYYY"),
        ]
        pastSeven.map(day => {
            calls.push(axios.get(`/api/weeks/bydate?date=${day}`))
        })
        nextSeven.map(day => {
            calls.push(axios.get(`/api/weeks/bydate?date=${day}`))
        })
        let weekData = []
        Promise.all(calls)
            .then(res => {
                res.map(week => {
                    if(week.data) {
                        Object.keys(week.data).map(key => {
                            if(nextSeven.includes(week.data[key].date)) {
                                weekData.push(week.data[key])
                            }
                        })
                    }
                })
                setLoading(false)
                setWeekData(weekData)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    // api call - getting employee data by active
    const getApiEmployees = () => {
        axios.get('/api/employees/active')
            .then(response => {
                setWorkForce(response.data)
                setUpdateEmp(false)
            })
            .catch(err => console.log(err))
    }

    // rendering hover display on name hover
    const triggerHover = (e, name) => {
        for (let lady of workforce) {
            if (lady.name === name) {
                if (screenSize > 800) {
                    setMouseX(e.pageX)
                    setMouseY(e.pageY)
                }
                setHovering(lady)
            }
        }
    }

    // helper function for returning lady object by name from list
    const getLadyFromList = (name) => {
        for (let lady of workforce) {
            if (lady.name === name) {
                return lady
            }
        }
    }

    // helper function to display time
    const humanTime = (time) => {
        switch(time) {
            case ("01:00"):
                return "1:00 am";
            case ("09:00"):
                return "9:00 am";
            case ("17:00"):
                return "5:00 pm"
        }
    }

    // 3 part shift render (refer to Manager.component for full commenting)
    const renderShifts = (day) => {
       
        let output = []
        let counter = 0
        let names = []
        workforce.map(lady => {
            names.push(lady.name)
        })
        
        weekData[day].shifts.map(shift => {
            const boxHeight = shiftBoxHeight(Number(shift.from.split(':')[0]), Number(shift.to.split(':')[0]))
            let count = 1
            let onShift = []
            shift.employees.map(employee => {
                if (names.includes(employee.name) && count <= 80000) {
                    const lady = getLadyFromList(employee.name)
                    onShift.push(
                        <div>
                            {
                                screenSize > 800 ?
                                    <Link to={`/viper-room-ladies/${employee.name}`}>
                                        <span 
                                            className="worker-name" 
                                            id={`${day}-worker-${counter}`}
                                            onMouseEnter={(e) => triggerHover(e, employee.name)}
                                            onMouseLeave={() => setHovering(null)}
                                            > 
                                            <div id={`${day}-workwrap-${counter}`} src={avatarDefault} >
                                                {
                                                    lady.media.length > 0 ?
                                                        <img src={lady.media[0].url} alt={lady.media[0].alt} />
                                                        :
                                                        <img id={`${day}-img-${counter}`} src={avatarDefault} /> 
                                                }
                                                {employee.name} 
                                            </div>                           
                                        </span>
                                    </Link>
                                    :
                                    <span 
                                        className="worker-name" 
                                        id={`${day}-worker-${counter}`}
                                        onClick={(e) => triggerHover(e, employee.name)}
                                        > 
                                        <div id={`${day}-workwrap-${counter}`} src={avatarDefault} >
                                            {
                                                lady.media.length > 0 ?
                                                    <img src={lady.media[0].url} alt={lady.media[0].alt} />
                                                    :
                                                    <img id={`${day}-img-${counter}`} src={avatarDefault} /> 
                                            }
                                            {employee.name} 
                                        </div>                           
                                    </span>
                            }
                        </div>
                    )
                    count++
                }
            })
            output.push(
                <div className="shift-wrapper" key={`${day}-shift-${counter}`}>
                    <div 
                        className="shift-card" 
                        style={
                            shift.selected ?
                                {
                                    border: "3px solid #1DB954",
                                    minHeight:`${boxHeight}vh`
                                }
                                :
                                {
                                    minHeight:`${boxHeight}vh`
                                }
                        }
                        id={`${day}-card-${counter}`} 
                    >
                        <span id={`${day}-from-${counter}`} > {humanTime(shift.from)} </span>
                        <div 
                            className="shift-workers" 
                            id={`${day}-workerlist-${counter}`} 
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: "0.9em",
                            }}
                        >
                            {onShift}
                        </div>
                        <span id={`${day}-to-${counter}`} > {humanTime(shift.to)} </span>
                    </div>
                </div>
            )
            counter++
            
        })
        return output
    }

    // helper function to determine shift height
    const shiftBoxHeight = (from, to) => {
        const timeLength = () => {
            if ((to - from) < 0) {
                return (24 - from + to)
            }
            return (to - from)
        }
        const height = (timeLength() / 24 * 80)
        return height
    }
    
    // api call - getting employees by search query
    const handleSearch = (e) => {
        if (e.target.value === '') {
            setUpdateEmp(true)
        } else {
            axios.get(`/api/employees/search/${e.target.value}`)
                .then(response => {
                    setWorkForce(response.data)
                })
                .catch(err => console.log(err))
        }
    }

    const goToLady = (name) => {
        props.history.push(`/viper-room-ladies/${name}`)
    }
    

    return (
        <div id="schedule-page-container">
            <Helmet>
                <title>Ladies Schedule for Brothels Brisbane The Viper Room</title>
                <meta name="description" content="With a variety of playmates to choose from, The Viper Room will have a companion to suit. Checkout who is available and read their biography,
                 with a current to the moment brothel schedule, you can be sure to have the most up to date information at The Viper Room." />
            </Helmet>
            <PageHeader page={'schedule'} />
            <div id="schedule-wrapper" onClick={() => {
                if (hovering) {
                    setHovering(null)
                }
            }} >
                <input type="text" placeholder="Search for your favourite playmate" onChange={(e)=> handleSearch(e)} style={weekData.length === 0 ? {display: "none"} : null} />
                {
                    hovering ?
                        <div 
                            id="hover-display"
                            style={
                                screenSize > 800 ?
                                    {
                                        left: `${mouseX+50}px`,
                                        top: `${mouseY-490}px`
                                    }
                                    :
                                    {
                                        position: "fixed",
                                        left: "10%",
                                        top: "20%"
                                    }
                            }
                            onClick={() => goToLady(hovering.name)}
                        >
                            {
                                hovering.media.length > 0 ?
                                    <img src={hovering.media[0].url} alt={hovering.media[0].alt} />
                                    :
                                    <img src="https://i.pinimg.com/originals/ec/d9/9a/ecd99ab2289902ebed28311f562938e4.png" />
                            }
                            <span> 
                                <span>{hovering.name} </span><br/><br/><br/><br/>
                                {hovering.ethnicity}  <br/>
                                Size: {hovering.size}  <br/>
                                Age: {hovering.age}  <br/>
                                Hair: {hovering.hair}  <br/>
                                Bust: {hovering.bust}  <br/>
                                About: {hovering.about} <br/><br/>
                            </span>
                        </div>
                        :
                        null
                }
                {
                    !loading ?
                        weekData.length <= 0 ?
                            <div className="no-schedule-prompt">
                                <h3> Our schedule is currently being updated </h3>
                                <p> We apologize for the inconvenience, we will publish it ASAP when all updates have been finished </p>
                                <p> In the meantime- please call us on <span> <a href="tel:07%203426%208126">07 3426 8126</a></span> to inquire about our current playmates availabe </p>
                            </div>
                            :
                            <div id="week-planner">
                                <div className='day-col'>
                                    <p> {moment().format("dddd (DD/MM)")} </p>
                                    <div>
                                        {weekData[0] ? renderShifts(0) : null}
                                    </div>
                                </div>

                                <div className='day-col'>
                                    <p>{moment().add(1, 'day').format("dddd (DD/MM)")}  </p>
                                    {weekData[1] ? renderShifts(1) : null}
                                </div>

                                <div className='day-col'>
                                    <p> {moment().add(2, 'day').format("dddd (DD/MM)")}  </p>
                                    {weekData[2] ? renderShifts(2) : null}
                                </div>

                                <div className='day-col'>
                                    <p>{moment().add(3, 'day').format("dddd (DD/MM)")} </p>
                                    {weekData[3] ? renderShifts(3) : null}
                                </div>

                                <div className='day-col'>
                                    <p>{moment().add(4, 'day').format("dddd (DD/MM)")} </p>
                                    {weekData[4] ? renderShifts(4) : null}
                                </div>

                                <div className='day-col'>
                                    <p>{moment().add(5, 'day').format("dddd (DD/MM)")} </p>
                                    {weekData[5] ? renderShifts(5) : null}
                                </div>

                                <div className='day-col'>
                                    <p>{moment().add(6, 'day').format("dddd (DD/MM)")} </p>
                                    {weekData[6] ? renderShifts(6) : null}
                                </div>
                            </div>
                        :
                        <div>
                            <br/>
                            <img className="loading-gif" src={loadingGif} />
                        </div>
                }
            </div>
            <div id="sched-btm-text">
                <p>
                    If you have a special playmate in mind or you prefer a particular hair colour etc. Blonde, Brunette, Short, Tall, Asian, Indian, Swedish, European, Jamaican, African or Polynesian. The Viper Room can find that special companion for you.
                    <br/><br/>
                    To find your special playmate simply <a href="tel:07%203426%208126">phone</a> or <a href="mailto:info@theviperroom.com.au">email</a> us info@theviperroom.com.au
                </p>
            </div>
            <PageFooter />
        </div>
    )

} 

export default SchedulePage