import React, { useState, useEffect } from 'react'
import ImageGallery from 'react-image-gallery';
import { Link } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import Axios from 'axios'
import { Helmet } from 'react-helmet'

const TourPage = () => {

    const [show, setShow] = useState(false);
    const [albums, setAlbums] = useState([])
    useEffect(() => {
        window.scrollTo(0, 0)

        // api call - getting album data
        Axios.get('/api/albums')
            .then(response => {
                setAlbums(response.data)
            })
    }, [])

    // displaying photos
    const renderPhotos = () => {
        let output = []
        albums.map(album => {
            let photos = []
            if (album.photos.length > 0) {
                let count = 0
                album.photos.map(photo => {
                    if (count > 0) {
                        photos.push({
                            original: photo.url,
                            thumbnail: photo.url,
                            originalAlt: photo.alt,
                            thumbnailAlt: photo.alt
                        })
                    }
                    count++
                })
                output.push(
                    <div className="tp-album-wrapper">
                        <div className="tp-album-cover">
                            <img src={album.photos[0].url} alt={album.photos[0].alt} />
                        </div>
                        <div className="tp-album-photos">
                            <ImageGallery items={photos} showPlayButton={false}/>
                        </div>
                                                    
                    </div>
                )
            }
        })
        return output
    }

    
    return (
        <div id="tour-page-container">
            <Helmet>
                <title>The Viper Room - Have a Tour of our brothel Brisbane and be tempted</title>
                <meta name="description" content="Our luxuriously appointed rooms include double showers, spas and disabled facilities. All rooms are tastefully themed and also include 24 hour streamed adult movies.We also offer a large reception lounge with private waiting rooms available. These private rooms cater for individual and small groups." />
            </Helmet>
            <PageHeader page={'tour'} />
            <div id="landing-banner">
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/room-1.6.jpg'} alt="blonde and brunette on bed in room 1 at Brisbane brothel The Viper Room" />
                <h1> <span>T</span>OUR </h1>
            </div>

            <p id="tour-desc" >
                Our <span>luxuriously</span> appointed rooms include double showers, spas and disabled facilities. All rooms are tastefully themed and also include 24 hour streamed adult movies.
                <br/><br/>
                We also offer a large reception lounge with <span>private waiting rooms</span> available. These private rooms cater for individual and small groups.
            </p>

            <div id="album-wrapper">
                {renderPhotos()}
            </div>  


            <PageFooter />
        </div>
    )
}

export default TourPage