import React, { useState, useEffect } from 'react'
import ReactCardFlip from 'react-card-flip';
import { Link, withRouter } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import RatesCard from '../minor_components/RatesCard.component'
import { Helmet } from 'react-helmet'

const RatesPage = () => {

    const [cardOne, setOne] = useState(false)
    const [cardTwo, setTwo] = useState(false)
    const [cardThree, setThree] = useState(false)
    const [cardFour, setFour] = useState(false)
    const [cardFive, setFive] = useState(false)
    const [cardSix, setSix] = useState(false)
    const [isFlipped, setFlipped] = useState(false)
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
      
    return (
        <div id="rates-page-container"> 
            <Helmet>
                <title>The Viper Room Rates and Services|Legal Brothels Brisbane</title>
                <meta name="description" content="As a legal Brisbane brothel The Viper Room is a safe and fun place to indulge your fantasies. Have a look at rates and possible services. Open 24 hours a day, 7 days a week" />
            </Helmet>
            <PageHeader page={'rates'} />

            <div id="landing-banner">
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/Violet.jpeg'} alt="service provider in blue and white top" />
                <h1> ABOUT OUR <span>SERVICES</span> </h1>
            </div>

            <div id="service-details">
                Under the Queensland Prostitution Act of 1999, we are unable to publish service details on our website. Under the same legislation we are unable to offer escort services.
                <br/><br/>
                As a legal Brisbane brothel The Viper Room is a safe and fun place to indulge your fantasies. Please ask the receptionist if you have a special request for a particular fantasy or service. Please note that some fantasies may incur an additional fee. The fees are negotiated directly with the playmate.
                <br/><br/>
                We are <span>open 24 hours a day, 7 days a week </span>and you are welcome to come in at anytime of the day or night and meet and discuss your desires with the gorgeous playmates available. No matter if you are after a sensuous service, a girlfriend experience, passive, submissive, hard core or to satisfy a particular fantasy or fetish, there is a playmate at The Viper Room, <span>Brisbane’s No 1 Brothel</span> that is sure to please.
                <br/><br/>
                Ladies and Couples are Welcome at The Viper Room
                <br/><br/>
                Mistress available by appointment only.
                <br/><br/>
                Only safe sex is practiced at this brothel.
                <br/><br/>
                You will be paying your room hire to The Viper Room and your negotiated playmate’s fee directly to the playmate.
                <br/><br/>
                Don’t forget to ask about our loyalty program offering substantial savings for regular visits. Conditions apply.
                <br/><br/>
            </div>

            <div id="rates-div">
                <div id="rates-list">

                    <h3> RATES </h3>

                    <RatesCard 
                        title="30 MINUTES STANDARD"
                        // overallPrice="170"
                        roomPrice="90"
                        ladyPrice="80"
                    />

                    <RatesCard 
                        title="45 MINUTES STANDARD"
                        // overallPrice="210"
                        roomPrice="110"
                        ladyPrice="100"
                        // prevPrice="250"
                    />

                    <RatesCard 
                        title="60 MINUTES STANDARD"
                        // overallPrice="260"
                        roomPrice="135"
                        ladyPrice="125"
                        // prevPrice="330"
                    />

                    <RatesCard 
                        title="90 MINUTES STANDARD"
                        // overallPrice="410"
                        roomPrice="210"
                        ladyPrice="200"
                        // prevPrice="420"
                        spa={true}
                    />

                    <RatesCard 
                        title="120 MINUTES STANDARD"
                        // overallPrice="490"
                        roomPrice="240"
                        ladyPrice="250"
                        // prevPrice="510"
                        spa={true}
                    />

                    <RatesCard 
                        title="OTHER SERVICES, FANTASIES AND TIMES"
                        overallPrice="CUSTOM BOOKING"
                        other={true}
                    />

                </div>

                <h3> BE TEMPTED... </h3>

                <div id="services-div">

                    <div id="card-list">
                        <div className="flip-card"
                            onMouseEnter={()=> setOne(true)}
                            onMouseLeave={()=> setOne(false)}
                        >
                            <ReactCardFlip isFlipped={cardOne} flipDirection="horizontal"  >
                                <div className="front-card" onClick={()=> setOne(true)} > 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/card-1.jpeg'} alt="black woman and white woman together"/>
                                    <p> DOUBLE PLEASURE IS OUR PLEASURE </p>
                                </div>
                                
                                <div className="back-card" onClick={()=> setOne(false)}> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/two-ladies.jpg'} alt="two brunettes in white on floor" />
                                    <p> Most of the naughty playmates enjoy each others company to ensure an experience to remember </p>
                                </div>
                            </ReactCardFlip>
                        </div>

                        <div className="flip-card"
                            onMouseEnter={()=> setTwo(true)}
                            onMouseLeave={()=> setTwo(false)}
                        >
                            <ReactCardFlip isFlipped={cardTwo} flipDirection="horizontal"  >
                                <div className="front-card" onClick={()=> setTwo(true)} > 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/LAXI7584.jpg'} alt="playmate on bed in red stockings" />
                                    <p> DESIRE AND FANTASY FULFILLMENT IS OUR SPECIALTY </p>
                                </div>
                                
                                <div className="back-card" onClick={() => setTwo(false)}> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/rose.jpg'} />
                                    <p> Our seductive playmates love to make dreams a reality. Let these sensuous vixens make your desires a reality. </p>
                                </div>
                            </ReactCardFlip>
                        </div>

                        <div className="flip-card" 
                            onMouseEnter={()=> setThree(true)}
                            onMouseLeave={()=> setThree(false)}
                        >
                            <ReactCardFlip isFlipped={cardThree} flipDirection="horizontal"  >
                                <div className="front-card" onClick={() => setThree(true)}> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/dom.jpg'} alt="man in suit with blind fold and brunette woman behind"/>
                                    <p> ASK ABOUT THE MISTRESS AND DOMINATRIX </p>
                                </div>
                                
                                <div className="back-card" onClick={() => setThree(false)}> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/MAHB4500.jpg'} alt="black and white image of two ladies leaning on wall with cuffs" />
                                    <p> Been naughty?<br/><br/>Our resident Mistress and Dominatrix will put you back in your place. </p>
                                </div>
                            </ReactCardFlip>
                        </div>

                        <div className="flip-card" 
                            onMouseEnter={()=> setFour(true)}
                            onMouseLeave={()=> setFour(false)}
                        >
                            <ReactCardFlip isFlipped={cardFour} flipDirection="horizontal"  >
                                <div className="front-card"> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/Role_Play.jpg'} alt="lady in nurses outfit" onClick={() => setFour(true)}/>
                                    <p> MAYBE A LITTLE SEXY ROLEPLAY IS IN ORDER </p>
                                </div>
                                
                                <div className="back-card"> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/lick.jpeg'} alt="two playmates in fantasy costumes" onClick={() => setFour(false)} />
                                    <p> Got a scene in mind? Bring a costume or select from the playmates kit. </p>
                                </div>
                            </ReactCardFlip>
                        </div>

                        <div className="flip-card" 
                            onMouseEnter={()=> setFive(true)}
                            onMouseLeave={()=> setFive(false)}
                        >
                            <ReactCardFlip isFlipped={cardFive} flipDirection="horizontal"  >
                                <div className="front-card"> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/EZEQ4010.jpg'} alt="black and white image of heel hooking g-string" onClick={() => setFive(true)} />
                                    <p> OUR PLAYMATES HAVE MAGIC HANDS </p>
                                </div>
                                
                                <div className="back-card"> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/violet2.jpeg'} alt="playmate laying on bed from behind" onClick={() => setFive(false)}/>
                                    <p> Find stress relief through sensual touch and exotic relaxation </p>
                                </div>
                            </ReactCardFlip>
                        </div>

                        <div className="flip-card" 
                            onMouseEnter={()=> setSix(true)}
                            onMouseLeave={()=> setSix(false)}
                        >
                            <ReactCardFlip isFlipped={cardSix} flipDirection="horizontal"  >
                                <div className="front-card" onClick={() => setSix(true)}> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/gf-pic.jpeg'} alt="stunning blonde at brothels Brisbane TVR on bed in room 1"/>
                                    <p> THE GIRLFRIEND EXPERIENCE </p>
                                </div>
                                
                                <div className="back-card" onClick={() => setSix(false)}> 
                                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/QVJG4680.jpg'} alt="blonde playmate kneeling on bed with sheet and green bottoms" />
                                    <p> After something a little more intimate and sensual. Our gorgeous companions know how to please. </p>
                                </div>
                            </ReactCardFlip>
                        </div>
                    </div>

                </div>
                <div id="book-btn">
                    <a href="tel:07%203426%208126" > <button> BOOK NOW </button> </a>
                </div>

            </div>

            <PageFooter />
        </div>
    )
}

export default RatesPage