import React from 'react'

const RatesCard = (props) => {
    return (
        <div className="rates-card" >
            <p className="deal-title" > {props.title} </p>
            <p className="deal-price"> 
                {
                    props.prevPrice ?
                        <div>
                            ${props.prevPrice}
                        </div>
                        :
                        null
                }
                {/* <span>{props.other ? null : "$" }</span>{props.overallPrice} */}
            </p>
            {props.other ? null : <p className="inc-p" > {/* INCL. LADIES FEE + ROOM HIRE */}</p>} 
            <div className="feature-list">
                {
                    props.other ?
                        <div>
                            <p> <i class="far fa-check-circle"></i> Longer Bookings can be accommodated </p> 
                            <p> <i class="far fa-check-circle"></i> With A Variety Of Playmates Offering A Range of Services And Fantasies To Fullfill Your Desires </p> 
                            <p> <i class="far fa-check-circle"></i> Extra Services To Be Negotiated with Playmate Directly </p> 
                        </div>
                        :
                        <div>
                            <p> <i class="far fa-check-circle"></i> Standard Service </p> 
                            {
                                props.spa ?
                                    <p> <i class="far fa-check-circle"></i> Spa Can Be Included </p> 
                                    :
                                    null
                            }
                            <p> <i class="far fa-check-circle"></i> Room Hire ${props.roomPrice} </p> 
                            <p> <i class="far fa-check-circle"></i> Playmates Fee To Be Paid Directly To The Playmate ${props.ladyPrice} </p> 
                            <p> <i class="far fa-check-circle"></i> Extra Services To Be Negotiated with Playmate Directly </p>
                        </div>
                } 
            </div>
            <a href="tel:07%203426%208126" > <button> BOOK NOW </button> </a>
        </div>
    )
}

export default RatesCard