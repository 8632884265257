import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import axios from 'axios'
import ImageGallery from 'react-image-gallery';
import moment from 'moment'
import { Helmet } from 'react-helmet'

const ProfilePage = (props) => {

    const [lady, setLady] = useState(null)
    const [isHere, setIsHere] = useState(false)
    const [week, setWeek] = useState(null)
    const [images, setImages] = useState([])
      

    // api call - getting lady info by name and current week info
    useEffect(()=>{
        window.scrollTo(0, 0)
        // call to set lady
        axios.get(`/api/employees/byname/${props.match.params.name}`)
            .then(response => {
                let photos = []
                response.data.media.map(photo => {
                    photos.push({
                        original: photo.url,
                        thumbnail: photo.url,
                        originalAlt: photo.alt,
                        thumbnailAlt: photo.alt
                    })
                })
                if (response.data.media.length === 0) {
                    photos.push({
                        original: 'https://viper-images.s3-ap-southeast-2.amazonaws.com/by_window.jpg',
                        thumbnail: 'https://viper-images.s3-ap-southeast-2.amazonaws.com/by_window.jpg',
                        originalAlt: 'Naked girl by window',
                        thumbnailAlt: 'Naked girl by window'
                    })
                }
                setImages(photos)
                setLady(response.data)
            })
        getApiWeekData()
    }, [])

    const getApiWeekData = () => {
        let calls = []
        const pastSeven = [
            moment().subtract(6, 'day').format("DD/MM/YYYY"),
            moment().subtract(5, 'day').format("DD/MM/YYYY"),
            moment().subtract(4, 'day').format("DD/MM/YYYY"),
            moment().subtract(3, 'day').format("DD/MM/YYYY"),
            moment().subtract(2, 'day').format("DD/MM/YYYY"),
            moment().subtract(1, 'day').format("DD/MM/YYYY"),
        ]
        const nextSeven = [
            moment().format("DD/MM/YYYY"),
            moment().add(1, 'day').format("DD/MM/YYYY"),
            moment().add(2, 'days').format("DD/MM/YYYY"),
            moment().add(3, 'days').format("DD/MM/YYYY"),
            moment().add(4, 'days').format("DD/MM/YYYY"),
            moment().add(5, 'days').format("DD/MM/YYYY"),
            moment().add(6, 'days').format("DD/MM/YYYY"),
        ]
        pastSeven.map(day => {
            calls.push(axios.get(`/api/weeks/bydate?date=${day}`))
        })
        nextSeven.map(day => {
            calls.push(axios.get(`/api/weeks/bydate?date=${day}`))
        })
        let weekData = []
        Promise.all(calls)
            .then(res => {
                res.map(week => {
                    if(week.data) {
                        Object.keys(week.data).map(key => {
                            if(nextSeven.includes(week.data[key].date)) {
                                weekData.push(week.data[key])
                            }
                        })
                    }
                })
                setWeek(weekData)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // rendering shift timetable
    const displayShift = (dayOfweek, slot) => {
        if (week) {
            for (let employee of week[dayOfweek].shifts[slot].employees) {
                if (employee.name === lady.name) {
                    return true
                }
            }
        }
        return false
    }


    if (!lady) {
        return (
            <div id="profile-page-container">
                loading...
            </div>
        )
    } else {
        return (
            <div id="profile-page-container">
                <Helmet>
                    <title> The Viper Room - Profile for {lady.name} </title>
                    <meta
                        name="description"
                        content={`${lady.name} (${lady.ethnicity}) - ${lady.about}`}
                    />
                </Helmet>
                <PageHeader />
                <div id="profile-details-wrapper">
                    <p id="profile-name"> 
                        {lady.name} 
                        {
                            week &&isHere ?
                                <div id="green-circle"> </div>
                                :
                                null
                        }
                    </p>
                    <div id="photo-carosel" >
                        <ImageGallery items={images} showPlayButton={false} showFullscreenButton={false} />
                    </div>
                    <p id="profile-desc"> 
                        <span> About </span><br/>
                        {lady.bio} <br/><br/>
                        {lady.ethnicity} <br/>
                        Size: {lady.size} <br/>
                        Age: {lady.age} <br/>
                        Hair: {lady.hair} <br/>
                        Bust: {lady.bust} <br/>
                        About: {lady.about} <br/>
                    </p>
                    <div id="schedule-div">
                        <div id="week-div">
                            <div className="day-card">
                                <div> 
                                    <span> {moment().format('dddd (DD/MM)').toUpperCase()} </span>
                                </div>
                                <div> 
                                    {
                                        displayShift(0, 0) ?
                                            <span > 1AM - 9AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(0, 1) ?
                                            <span> 9AM - 5PM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(0, 2) ?
                                            <span> 5PM - 1AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                            </div>
                            <div className="day-card">
                                <div> 
                                    <span> {moment().add(1, 'day').format('dddd (DD/MM)').toUpperCase()} </span>
                                </div>
                                <div> 
                                    {
                                        displayShift(1, 0) ?
                                            <span> 1AM - 9AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(1, 1) ?
                                            <span> 9AM - 5PM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(1, 2) ?
                                            <span> 5PM - 1AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                            </div>
                            <div className="day-card">
                                <div> 
                                    <span> {moment().add(2, 'day').format('dddd (DD/MM)').toUpperCase()} </span>
                                </div>
                                <div> 
                                    {
                                        displayShift(2, 0) ?
                                            <span> 1AM - 9AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(2, 1) ?
                                            <span> 9AM - 5PM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(2, 2) ?
                                            <span> 5PM - 1AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                            </div>
                            <div className="day-card">
                                <div> 
                                    <span> {moment().add(3, 'day').format('dddd (DD/MM)').toUpperCase()} </span>
                                </div>
                                <div> 
                                    {
                                        displayShift(3, 0) ?
                                            <span> 1AM - 9AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(3, 1) ?
                                            <span> 9AM - 5PM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(3, 2) ?
                                            <span> 5PM - 1AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                            </div>
                            <div className="day-card">
                                <div> 
                                    <span> {moment().add(4, 'day').format('dddd (DD/MM)').toUpperCase()} </span>
                                </div>
                                <div> 
                                    {
                                        displayShift(4, 0) ?
                                            <span> 1AM - 9AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(4, 1) ?
                                            <span> 9AM - 5PM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(4, 2) ?
                                            <span> 5PM - 1AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                            </div>
                            <div className="day-card">
                                <div> 
                                    <span> {moment().add(5, 'day').format('dddd (DD/MM)').toUpperCase()} </span>
                                </div>
                                <div> 
                                    {
                                        displayShift(5, 0) ?
                                            <span> 1AM - 9AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(5, 1) ?
                                            <span> 9AM - 5PM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(5, 2) ?
                                            <span> 5PM - 1AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                            </div>
                            <div className="day-card">
                                <div> 
                                    <span> {moment().add(6, 'day').format('dddd (DD/MM)').toUpperCase()} </span>
                                </div>
                                <div> 
                                    {
                                        displayShift(6, 0) ?
                                            <span> 1AM - 9AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(6, 1) ?
                                            <span> 9AM - 5PM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                                <div> 
                                    {
                                        displayShift(6, 2) ?
                                            <span> 5PM - 1AM </span>
                                            :
                                            <span> - </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <a href="tel:07%203426%208126"> <button> BOOK NOW </button></a>  <br/>
                        <Link to="/viper-room-ladies"><i class="fas fa-chevron-circle-left"></i> Back to playmates  </Link>
    
                    </div>
                </div>
                <PageFooter />
            </div>
        )
    }
}

export default ProfilePage