import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import axios from 'axios'
import Moment from 'react-moment'
import loadingGif from '../../images/loading.gif'
import { Helmet } from 'react-helmet'

const BlogPage = () => {

    const [blogs, setBlogs] = useState([])
    const [loading, setLoading] = useState(true)

    // api call - getting blogs 
    useEffect(() => {
        window.scrollTo(0, 0)
        axios.get('/api/blogs?limit=20')
            .then(response => {
                setBlogs(response.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [])

    // rendering blog list on front end
    const renderBlogs =() => {
        let output = []
        let count = 0
        blogs.map(blog => {
            const title = blog.title.split(' ').join('-')
            output.push(
                <div className="bp-list-bi">
                    <div className="bp-list-bi-top">
                        <h2> {blog.title.length > 20 ? blog.title.slice(0, 20) + '...' : blog.title}  </h2>
                        <span className="bp-list-bi-title"> <Moment date={blog.date} format="MMMM DD, YYYY" /> </span><br/>
                        <Link to={`/blog/${title}`}> Read more</Link>
                    </div>
                    <Link to={`/blog/${title}`}>
                        {
                            blog.image && blog.image.url ?
                                <img src={blog.image.url} alt={blog.image.alt} />
                                :
                                null
                        }
                    </Link>
                </div>
            )
            count++
        })
        return output
    }

    return (
        <div id="blog-page-container">
            <Helmet>
                <title>What's happening at Brisbane brothels The Viper Room</title>
                <meta name="description" content="Read the latest news on what's going on with our playmates here!" />
            </Helmet>
            <PageHeader page={'blog'} />
            <div id="landing-banner">
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/Juliana.jpeg'} alt="European beauty laying on bed in black" />
                <h1> WHAT'S <span>HAPPEN</span>ING? </h1>
            </div>

            <p id="blog-welcome">
                Read the latest news on what's going on with our playmates here!
            </p>

            <div id="bp-list">
                {
                    loading ?
                        <img className="loading-gif" src={loadingGif} />
                        :
                        null
                }
                {renderBlogs()}
            </div>
            <PageFooter />
        </div>
    )
}

export default BlogPage