import React from 'react'
import { Link } from 'react-router-dom'


const PageFooter = () => {

    return (
        <div>            
            <div id="page-footer">
                <div id="footer-left">
                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/the-viper-room.png'} alt="The Viper Room logo" /><br/>
                    <span>  
                        The Viper Room is a Queensland Legal Brothel. 
                        Located only 8 mins from Brisbane CBD and only a short trip on the motorway 
                        from the Gold Coast, Ipswich, Toowoomba and surrounding areas.
                    </span>
                </div>
                <div id="footer-links">
                    <p className='footer-title' > USEFUL LINKS </p>
                    <Link to='/'> Home </Link>
                    <Link to='/viper-room-ladies'> Playmates </Link>
                    <Link to='/discretion'> Discretion </Link>
                    <Link to='/our-services'> Rates & Services </Link>
                    <Link to='/contact'> Contact </Link>
                </div>
                <div id="footer-contact">
                    <p className='footer-title' > CONTACT US </p>
                    <a href="tel:07%203426%208126"> 07 3426 8126 </a>
                    <a target="blank" href="https://www.google.com/maps/place/945+Fairfield+Rd,+Yeerongpilly+QLD+4105/@-27.5373141,153.008752,17z/data=!3m1!4b1!4m5!3m4!1s0x6b915006252e64f3:0x5782b0d3759daa65!8m2!3d-27.5373189!4d153.0109407" > 
                        945 Fairfield Rd, Yeerongpilly, Brisbane 
                    </a>
                    <a href='mailto:info@theviperroom.com.au'> info@theviperroom.com.au </a>
                    <Link to='/schedule'> Open Hours: 24/7 </Link>
                    <Link to='/privacy-policy'> Privacy Policy </Link>
                </div>
                <div id="footer-socials">
                    <p className='footer-title' > SOCIALS </p>
                    <span> FOLLOW US ON </span>
                    <a target="blank" href="https://www.instagram.com/the_viper_room_bne/"> <i class="fab fa-instagram" id="insta-icon"></i> </a> 
                    <a target="blank" href="https://twitter.com/TheViperRoomAU/"> <i class="fab fa-twitter" id="twitter-icon"></i> </a> 
                </div>
            </div>
            <p id="page-footer-text-sml" > Copyright 2020 | All Rights Reserved | The Viper Room  </p>
        </div>
    )
}

export default PageFooter