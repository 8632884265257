import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import loadingGif from '../../images/loading.gif'
import Moment from 'react-moment';

const BlogManage = () =>  {

    // ------------------ VERIFICATION ------------------

    const [verified, setVerified] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get('/api/auth/user')
            .then(response => {
                if (response.status = 200) {
                    setVerified(true)
                    setLoading(false)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    // --------------------------------------------------


    const [uploading, setUploading] = useState(false)
    const [file, setFile] = useState(null)
    const [updated, setUpdated ] = useState(false)
    const [blogs, setBlogs] = useState([])

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [imageAlt, setImageAlt] = useState('')
    const [changeAlt, setChangeAlt] = useState(null)


    // api call for album data on required updates
    useEffect(() => {
        axios.get('/api/blogs')
            .then(response => {
                setBlogs(response.data)
                setUpdated(false)
            })
            .catch(err => console.log(err))
    }, [updated])

    // api call - creating blog   
    const addBlog = () => {
        setUploading(true)
        const formData = new FormData()
        formData.append('image', file)    
        formData.append('title', title)    
        formData.append('content', content)    
        formData.append('imageAlt', imageAlt)    
        axios({
            method: 'post',
            url: '/api/blogs/create',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
            setUploading(false)
            setUpdated(true)
            resetInputs()
        })
        .catch(err => console.log(err))
    }

    // api call - deleting blog
    const deleteBlog = (id) => {
        setUploading(true)
        axios.delete(`/api/blogs/delete/${id}`)
            .then(response => {
                setUploading(false)
                setUpdated(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const editAlt = (blogId) => {

        axios.put(`/api/blogs/edit-alt/${blogId}`, {
            alt: changeAlt[1]
        }).then(response => {
            if(response.status === 200) {
                setChangeAlt(null)
                setUpdated(true)
            } 
        }).catch(err => console.log(err))
    }

    // displaying blogs on component
    const renderBlogs = () => {
        let output = []
        blogs.map(blog => {
            output.push(
                <div className="blog-item">
                    {
                        blog.image && blog.image.url ?
                            <img src={blog.image.url} />
                            :
                            null
                    }
                    <div>
                        <span className="bi-date"><Moment format="MMMM DD, YYYY" date={blog.date} /></span> <br/>
                        <span className="bi-title"> {blog.title} </span><br/><br/>
                        <span className="bi-content"> {blog.content} </span><br/>
                        {
                            changeAlt && changeAlt[0] === blog.image.key ?
                                <div>
                                    <input 
                                        type='text' 
                                        value={changeAlt[1]} 
                                        onChange={(e) => setChangeAlt([blog.image.key, e.target.value])}
                                        id="alt-edit-input"
                                    /> 
                                    <span 
                                        id="alt-edit" 
                                        onClick={() => editAlt(blog._id)}
                                    > 
                                        save 
                                    </span>  
                                </div>
                                :
                                <span id="alt-edit" onClick={() => setChangeAlt([blog.image.key, blog.image.alt])}> edit alt </span>
                        }<br/><br/>
                        <button onClick={()=> deleteBlog(blog._id)} > delete </button>
                    </div>
                </div>
            )
        })
        return output
    }

    const resetInputs = () => {
        setFile(null)
        setTitle('')
        setContent('')
        setImageAlt('')
    }

  
    // initial page verification
    if (loading) {
        return (
            <div>
                loading...
            </div>
        )
    }
    // Redirects back to home if not verified && after loading
    if (!verified && !loading) {
        return (
            <Redirect to="/" />
        )
    }

    if (verified) {

        return (
            <div id="employees-page-container">
                <div id="blog-mananger">
                    <div id="bm-left">
                        <Link to="/dashboard"> <button id="home-btn"> Home </button> </Link>
                        <h3> Blog Manager </h3>
                        <span> <strong>Add new</strong> </span>
                        <div className="form">
                            <span> Title </span> <br/>
                            <input type="text" value={title} placeholder="Enter blog title" onChange={(e) => setTitle(e.target.value)} /><br/>
                            <span> Content </span> <br/>
                            <textarea type="text" value={content} placeholder="Type blog here..." onChange={(e) => setContent(e.target.value)} /><br/>
                        </div>
                        <div id="upload-div">
                            <span> Upload Image </span> <br/>
                            <input type="file" name="image" onChange={(e) => setFile(e.target.files[0])} /> <br/>
                            <input type="text" placeholder="Enter alt text" className="alt-text-input" value={imageAlt} onChange={(e) => setImageAlt(e.target.value)} />
                            <button onClick={addBlog}> Add Blog Post </button>
                        </div>
                        {uploading ? <img className="loading-gif" src={loadingGif} /> : null }
                    </div>

                    <div id="bm-right">
                            {renderBlogs()}
                    </div>
                </div>
            </div>
        
        )
    }


} 

export default BlogManage