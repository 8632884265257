import React from 'react'

const LadyForm = (props) => {
    return (
        <div className="form">
            <span> Name </span> <br/>
            <input type="text" value={props.name} name="name" onChange={(e) => props.handleChange(e)} /><br/>
            <span> Bio </span> <br/>
            <textarea type="text" value={props.bio} name="bio" onChange={(e) => props.handleChange(e)} /><br/>
            <span> Ethnicity </span> <br/>
            <input type="text" value={props.ethnicity} name="ethnicity" onChange={(e) => props.handleChange(e)} /><br/>
            <span> Size </span> <br/>
            <input type="number" value={props.size} name="size" onChange={(e) => props.handleChange(e)} /><br/>
            <span> Age </span> <br/>
            <input type="text" value={props.age} name="age" onChange={(e) => props.handleChange(e)} /><br/>
            <span> Hair </span> <br/>
            <input type="text" value={props.hair} name="hair" onChange={(e) => props.handleChange(e)} /><br/>
            <span> Bust </span> <br/>
            <input type="text" value={props.bust} name="bust" onChange={(e) => props.handleChange(e)} /><br/>
            <span> About </span> <br/>
            <input type="text" value={props.about} name="about" onChange={(e) => props.handleChange(e)} /><br/>
    
            <button onClick={props.handleSubmit} > add </button> 
        </div>
    )
}

export default LadyForm