import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import Moment from 'react-moment';
import LadyCardHover from '../minor_components/LadyCardHover.component'
import LadyCard from '../minor_components/LadyCard.component'
import moment from 'moment'
import { Helmet } from 'react-helmet'

const LandingPage = () => {

    const [reason, setReason] = useState(null)
    const [onToday, setToday] = useState([])
    const [hovering, setHovering] = useState(null)
    const [screenSize , setScreenSize] = useState(null)
    const [blogs, setBlogs] = useState([])

    // scroll to top on page load / and settting screen size for mobile hover adjustments
    useEffect(() => {
        window.scrollTo(0, 0)
        setScreenSize(window.innerWidth)
    }, [])

    // getting today's working ladies
    useEffect(() => {
        // date formatting to query monday
        const date = new Date
        const monday = moment().subtract((date.getDay() === 0 ? 6 : (date.getDay() - 1)), 'days')
        const year = date.getFullYear()

        // api call - getting week by monday date
        axios.get(`/api/weeks/bydate?date=${monday.format("DD/MM/YYYY")}`)
            .then(response => {

                // iterating over days of week
                ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday','sunday'].map(day => {
                    if (moment().format("DD/MM/YYYY") === response.data[day].date) {
                        let workers = []
                        let toUpdated = []
                        let girls = []
                        // determining current ladies on shift (by time hours)
                        if (date.getHours() < 9) {
                            workers.push(...response.data[day].shifts[0].employees)
                        } else if (date.getHours() >= 9 && date.getHours() < 17) {
                            workers.push(...response.data[day].shifts[1].employees)
                        } else {
                            workers.push(...response.data[day].shifts[2].employees)
                        }

                        // pushing api call promise to get lady data
                        workers.map(lady => {
                            toUpdated.push(axios.get(`/api/employees/byname/${lady.name}`))
                        })

                        Promise.all(toUpdated)
                            .then(response => {
                                response.map(girl => {
                                    girls.push(girl.data)
                                })
                                
                                // saving all girls on shift to state
                                setToday(girls)
                            })
                            .catch(err => console.log(err))
                    }
                })
            })
            .catch(err => console.log(err))

        // api call - getting most recent blogs (6)
        axios.get('/api/blogs?limit=6')
            .then(response => {
                setBlogs(response.data)
            })
            .catch(err => console.log(err))
    }, [])

    // rendering the ladies list
    const renderTodaysList = () => {
        let output = []
        let count = 1
        onToday.map(lady => {
            if(lady && count <= 8) {
                output.push(
                    <div>
                        {
                            hovering && hovering.name === lady.name ?
                                <LadyCardHover 
                                    lady={lady} 
                                    // offHover={() => setHovering(null)} 
                                />
                                :
                                screenSize > 800 ?
                                    <LadyCard 
                                        lady={lady}
                                        onHover={() => setHovering(lady)}
                                    />
                                    :
                                    <LadyCard 
                                        lady={lady}
                                        handleClick={() => setHovering(lady)}
                                    />
                        }
                        
                    </div>
                )
            }
            count++
        })
        return output
    }

    // displaying blog posts on client
    const renderBlogs = () => {
        let output = []
        blogs.map(blog => {
            const title = blog.title.split(' ').join('-')
            output.push(
                <Link to={`/blog/${title}`}>
                    <div className="blog-item">
                        {
                            blog.image && blog.image.url ?
                                <img src={blog.image.url} alt={blog.image.alt} />
                                :
                                null
                        }
                        <p> 
                            {blog.title.length > 24 ? blog.title.slice(0, 24) + '...' : blog.title} <br/><br/>
                            <span> <Moment date={blog.date}  format="MMMM DD, YYYY" /> </span>
                        </p>
                    </div>
                </Link>
            )
        })
        return output
    }

   
    return (
        
        <div id="landing-page-container" >
            <Helmet>
                <title> The Viper Room The Best Legal Brisbane Brothel </title>
                <meta
                    name="description"
                    content="Queensland’s longest established legal Brisbane brothel, The Viper Room. 
                    Renowned for being home to Brisbane’s hottest playmates, with a warm and friendly hospitality and a passion for pleasure and satisfaction"
                />
            </Helmet>
            <PageHeader page="home" />

            <div id="landing-banner">
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/DVHB5139.jpg'} alt="Playmate laying down" />
                <h1> A <span>LEGAL</span> BRISBANE BROTHEL </h1>
            </div>
            <div id="landing-div-1" >
                <p className="content-text" >
                    Queensland’s longest established Brisbane brothel, The Viper Room. 
                    Renowned for being home to Brisbane’s hottest playmates, with a warm and friendly hospitality and a passion for pleasure and satisfaction
                </p>
                <div id="landing-div-2" >
                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/landing-2.jpg'} alt="black and white image of blonde lady kneeling on chair" />
                    <p id="legal-age-prompt" >
                        BY USING THIS WEBSITE YOU AGREE THAT YOU ARE OVER 18 YEARS OLD*
                    </p>
                </div>
            </div>

            <div id="todays-list">
                <div className="content-sec" >
                    <span className="content-heading"> Today's Sexy Playmates </span><br/><br/>
                    <span> 
                        Where your pleasure is our pleasure. 
                        Come and be tempted by the finest playmates in Brisbane. Sensual, seductive, sweet or adventurous, 
                        wild and playful there’s a gorgeous playmate to suit every taste, fantasy or desire at the premier Brothel Brisbane, The Viper Room 
                    </span>
                    {/* <div id="see-more-prompt">
                        <Link to="/viper-room-ladies"> <span>MORE STUNNING PLAYMATES</span> </Link>
                    </div> */}
                </div>

                <div id="tl-display" >
                    <button onClick={() => {
                        let div = document.getElementById('tl-list')
                        div.scrollBy((window.innerWidth * -1), 0)
                    }}>
                        <i class="fas fa-angle-left"></i> 
                    </button>
                    <div id="tl-list" onMouseLeave={()=>setHovering(null)} style={{scrollBehavior: "smooth"}}>
                        {renderTodaysList()}
                    </div>
                    {
                        onToday.length === 0 ?
                            <p> Check the <Link to="/schedule" >schedule</Link> if our playmates aren't displaying here</p>
                            :
                            null
                    }
                    <button onClick={() => {
                        let div = document.getElementById('tl-list')
                        div.scrollBy(window.innerWidth, 0)
                    }}>
                        <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>

            <h2 id="expore-heading"> Go on, Explore more! </h2><br/><br/>
            <div id="option-bar-1" >
                <Link to="/viper-room-ladies">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/ladies_button.jpg'} alt="service provider with hands on hips in pink" />
                    </div>
                </Link>
                <Link to="/our-services">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/rates-n-services-button.png'} alt="man and woman hugging" />
                    </div>
                </Link>
                <Link to="/schedule"> 
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/schedule_button.png'} alt="Brisbane lady applying red lipstick" />
                    </div>
                </Link>
                <Link to="/tour">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/rooms_button.jpg'} alt="image of room 1 bed and spa" />
                    </div>
                </Link>
                <Link to="/viper-room-ladies">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/gallery_button.png'} alt="beautiful lady with finger to lips" />
                    </div>
                </Link>
                <Link to="/contact">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/location_button.png'} alt="The Viper Room from outside at night with lights "/>
                    </div>
                </Link>
            </div>

            <div className="content-wrapper">
                <div className="content-sec">
                    <span className="content-heading"> A BRISBANE BROTHEL WITH A DIFFERENCE </span><br/><br/>
                    <span className="sub-heading" >Welcome to the best Brothel in Brisbane!</span><br/>
                    <span>  
                        If you are looking for a friendly, respectful and welcoming Brisbane brothel, open 24 hours a day, 7 days a week then The Viper Room is the best of all brothels in Brisbane.<br/><br/>
                        Why be alone? <br/><br/>
                        We take the time to get to know you, to make you feel special and to make you feel comfortable.
                        Whether you are after companionship, relaxation or to fulfill your deepest desires, we have a playmate to fit the bill.
                    </span>
                </div>
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/c_two-ladies.jpeg'} alt="two brunettes in white laying on top of each other" />
            </div>

            <div className="content-wrapper">
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/blonde-brunette.jpg'} alt="fun blonde and brunette dancing"/>
                <div className="content-sec">
                    <span className="content-heading"> YOUR PLEASURE IS OUR PLEASURE </span><br/><br/>
                    <span>  
                        Make your fantasies a reality with Brisbane’s hottest playmates. 
                        Be tempted at The Viper Room, no matter if you are looking for a sensual and relaxing stay or a bit of naughty fun, 
                        The Viper Room has the playmate to ensure you have an experience to remember. The Viper Room is home to Brisbane’s hottest playmates. <br/><br/>
                        With up to 50 different playmates working in any week, there is something to suit everyone. 
                        These playmates are predominately Caucasian with a sprinkling of the exotic to please even the most discerning clients. Blondes, Brunettes, red heads, size 6 to 14, ages 18-40, 
                        sensual, playful, naughty and very sexy there is a playmate to suit every fantasy.
                    </span>
                </div>
            </div>

            <div className="content-wrapper">
                <div className="content-sec">
                    <span className="content-heading"> WHERE FANTASY BECOMES A REALITY </span><br/><br/>
                    <span>  
                        We are known for being the friendliest and most happy establishments to visit. 
                        Our premises are 5 star and offers discretion at every level from parking to private waiting rooms and separate exit.
                        <br/><br/>
                        Our gorgeous rooms are tastefully themed with 24 hour in house adult movies. 
                        Spa, double shower and disability suits are available. 
                        We also offer private waiting rooms for guests who prefer absolute discretion and for that one on one meeting with our stunning playmates.
                    </span>
                </div>
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/c_blonde_sitting.jpeg'} alt="beautiful Brisbane blonde wearing pink sitting on floor" />
            </div>

            <div id="background-slider-container">
                <div id="reason-div" >
                    <h3> WHY CHOOSE US OVER OTHER BRISBANE BROTHELS? </h3>
                    <div className="reason-card" >
                        <button onClick={() => {
                            if (reason === 'one') {
                                setReason(null)
                            } else {
                                setReason('one')
                            }
                        }} >
                            <div>
                                <i class="far fa-smile-wink"></i>
                                <span>
                                    WE ARE PROFESSIONAL AND DISCREET
                                </span>
                            </div>
                            <i id='down-arrow' class="fas fa-chevron-down" style={reason === "one" ? {transform:"rotate(180deg)", transitionDuration:"0.5s"} : null } ></i>
                        </button>
                        <p className="reason-content" style={reason === 'one' ? null : {display: "none"}} >
                            We are professional – there’s no tricks or games when you visit our brothel in Brisbane. We are a business that is client-focused.
                            <br/><br/>
                            We are discreet – all your details are kept confidential at all times. 
                            You never need to worry that information will be given to any other party. 
                            There is on site private parking, private waiting rooms available and a separate discreet exit.
                        </p>
                    </div>
                    <div className="reason-card" >
                        <button onClick={() => {
                            if (reason === 'two') {
                                setReason(null)
                            } else {
                                setReason('two')
                            }
                        }} >
                            <div>
                                <i class="fas fa-star"></i>
                                <span>
                                    CLEANLINESS AND COMFORT ARE PARAMOUNT
                                </span>
                            </div>
                            <i id='down-arrow' class="fas fa-chevron-down" style={reason === "two" ? {transform:"rotate(180deg)", transitionDuration:"0.5s"} : null }></i>
                        </button>
                        <p className="reason-content" style={reason === 'two' ? null : {display: "none"}} >
                            Cleanliness and comfort are paramount – we didn’t get our reputation as the best Brisbane brothel without considering these key factors. <br/><br/>
                            We go above and beyond to make sure that our playmates and yourself are kept safe and comfortable at all times. 
                            Feel confident in knowing that it is safe sex practices only at Brisbane’s best brothel The Viper Room.
                        </p>
                    </div>
                    <div className="reason-card" >
                        <button onClick={() => {
                            if (reason === 'three') {
                                setReason(null)
                            } else {
                                setReason('three')
                            }
                        }} >
                            <div>
                                <i class="fas fa-hand-holding-heart"></i>
                                <span>
                                    CATER TO YOUR NEEDS AND DESIRES IDEALLY LOCATED
                                </span>
                            </div>
                            <i id='down-arrow' class="fas fa-chevron-down" style={reason === "three" ? {transform:"rotate(180deg)", transitionDuration:"0.5s"} : null }></i>
                        </button>
                        <p className="reason-content" style={reason === 'three' ? null : {display: "none"}} >
                            Location – We are ideally located just outside the main Brisbane CBD, with easy access to our brothel via public transport, but far enough away to allow for some anonymity.
                            <br/><br/>
                            We cater to all needs and desires – We love to indulge your fantasies, no matter what your expectations from our Brisbane brothel, 
                            we will try to exceed it. Should you have a particular desire, you are always welcome to contact us with your requests and we will try our best to accommodate. 
                            We truly are the best Brisbane brothel, why not find out for yourself?
                        </p>
                    </div>
                    <a href="tel:07-3426-8126"><button id="call-btn" > CALL NOW </button></a>
                </div>


                <div id="testimony-div">
                    <p id="main-test"> 
                        “Brisbane's best brothel! This place has the hottest, nicest ladies and a clean happy establishment, truly lives up to it's reputation of the best brothel Brisbane.”- B.N
                    </p>
                    <div id="test-wrapper">
                        <div className="test-card"  >
                            “Viper Room advertises itself as the best Brisbane brothel. 
                            Having had my first experience there recently, I have to agree. Professional establishment, private, and the lady I was with fulfilled my every need. 
                            Will definitely visit again in the future.”<br/><span>-Mr. B </span>
                        </div>
                        <div className="test-card" >
                            "Beautiful ladies, perfect ending to a good night out. Highly recommend."<br/> <span>-A.W</span>
                        </div>
                        <div className="test-card" >
                            “Wonderful facilities at this Brothel, a great selection of lovely ladies. Friendly staff. Great place to visit in Brisbane.”<br/> <span>-G.S</span>
                        </div>
                        <div className="test-card" >
                            "Amazing service, the ladies are definitely the best I’ve experienced. Chanel showed me thing I never thought were possible. Highly recommend their service. Left with a smile 🙂"<br/> <span>-N.B</span>
                        </div>
                    </div>
                </div>
            </div>
            

            <h3 id="open-24"> OPEN 24 HOURS, 7 DAYS A WEEK </h3>

            <div id="blog-div">
                <h3> Find out what's happening at legal Brisbane brothel The Viper Room </h3>
                <p id="prompt-text">For the latest updates and events please see our What’s happening page.</p>
                <div id="blog-list">

                    {renderBlogs()}

                </div>

                <Link to="/blogs"> <button> MORE UPDATES </button></Link>

            </div>

            <div id="visit-div" >
                <div>
                    <p className="content-heading"> Visit Us </p>
                    <div id="visit-info">
                        <a target="blank" href="https://www.google.com/maps/place/945+Fairfield+Rd,+Yeerongpilly+QLD+4105/@-27.5373141,153.008752,17z/data=!3m1!4b1!4m5!3m4!1s0x6b915006252e64f3:0x5782b0d3759daa65!8m2!3d-27.5373189!4d153.0109407" > 
                            <span> <div><i class="fas fa-map-marker-alt" id="i-adj"></i></div> 945 Fairfield Rd, Yeerongpilly QLD 4105 </span>
                        </a> <br/>
                        <a href = "mailto:info@theviperroom.com.au"> <span> <div><i class="fas fa-envelope-square"></i></div> info@theviperroom.com.au </span></a>  <br/> 
                        <a href="tel:07%203426%208126"> <span> <div><i class="fas fa-phone-square"></i></div> (07) 3426 8126 </span> <br/> </a>
                        <a href="https://www.instagram.com/the_viper_room_bne/" target="blank"><span> <div><i class="fab fa-instagram"></i></div> @the_viper_room_bne </span></a>  <br/>
                        <a href="https://twitter.com/theviperroomau" target="blank">  <span> <div><i class="fab fa-twitter"></i></div> @theviperroomau </span> </a> <br/>
                    </div>
                </div>
                <div id="map-display">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3537.8115207404394!2d153.00875201566916!3d-27.537314126176096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915006252e64f3%3A0x5782b0d3759daa65!2s945%20Fairfield%20Rd%2C%20Yeerongpilly%20QLD%204105!5e0!3m2!1sen!2sau!4v1592705283750!5m2!1sen!2sau"></iframe>
                </div>
            </div>
            <div id="footer-graphic">
                <div id="logo-footer">
                    <img  src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/the-viper-room.png'} alt="The Viper Room be tempted logo" />
                </div>
                <img id="back-img-footer" src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/c_threesome.jpeg'} alt="3 gorgeous playmates sitting in the lounge at The Viper Room" />
            </div>

            <PageFooter />
            
        </div>
    )
}

export default LandingPage