import React, {useEffect} from 'react'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import { Helmet } from 'react-helmet'

const PrivPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Helmet>
                <title> The Viper Room - Privacy Policy </title>
                <meta
                    name="description"
                    content="In the course of using this site you may provide The Viper Room with personal information. The Viper Room will use all reasonable measures to protect any such information from being used for any other purpose other than the provision of The Viper Room."
                />
            </Helmet>
            <PageHeader />
            <div id="privacy-page">
                <h3> PRIVACY POLICY </h3>
                <p> 
                    In the course of using this site you may provide The Viper Room with personal information. The Viper Room will use all reasonable measures to protect any such information from being used for any other purpose other than the provision of The Viper Room.
                    <br/><br/>
                    <span>Security of Captured Data</span><br/>
                    When capturing customer data it is passed through a secure The Viper Room server using encryption technology to ensure that your information is protected when being sent over the Internet. This also applies to information that may be collected for The Viper Room by an agency ie. when subscribing, where it will also be passed through a secure server.
                    <br/><br/>
                    Additionally, all stored customer information is protected from unauthorised access through the use of secure passwords and user log ons or other security procedures
                    <br/><br/>
                    <span>The Viper Room and Spam</span><br/>
                    Spam is illegal. The Viper Room does not support spam in anyway. By becoming a Viper Room member you will always have the option to unsubscribe from our newsletter database.
                </p>
            </div>
            
            <PageFooter />
        </div>
    )
}

export default PrivPolicy