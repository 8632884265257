import React, { useState, useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

const AdminLogin = () => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [flash, setFlash] = useState(null)
    const [verified, setVerified] = useState(false)

    // Login request to API
    const login = () => {
        axios.post('/api/auth/login', {
            username: username,
            password: password
        }).then(response => {
            if (response.status === 200) {
                setVerified(true)
            }
            setFlash({
                success: false,
                message: "Invalid username or password"
            })
        }).catch(err => {
            setFlash({
                success: false,
                message: "Invalid username or password"
            })
        })
    }

    // If login successful redirect to admin dashboard
    if (verified) {
        return (
            <Redirect to="/dashboard" />
        )
    }

    return (
        <div id="admin-page-container">
            <div id="login-input">
                <input 
                    type="text" 
                    placeholder="Enter username" 
                    onChange={(e)=> setUsername(e.target.value)} 
                    /><br/>
                <input 
                    type="password" 
                    placeholder="Enter password" 
                    onChange={(e)=> setPassword(e.target.value)}
                    onKeyPress={(e)=> {
                        if(e.key === "Enter") {
                            login()
                        }
                    }}
                    /><br/>
                <button onClick={login} > Log in </button>
                {
                    flash ?
                        <span style={{color: "red"}}> {flash.message} </span>
                        :
                        null
                }
            </div>
        </div>
    )
}

export default AdminLogin