import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

const LadyCardHover = (props) => {
    
    const lady = props.lady

    return (
        <Link to={`/viper-room-ladies/${lady.name}`}>
            <div className="lady-card-hover" onMouseLeave={props.offHover} >
                {
                    lady.media.length > 0 ?
                        lady.media.length > 1 ?
                            <img src={lady.media[1].url} alt={lady.media[1].alt} />
                            :
                            <img src={lady.media[0].url} alt={lady.media[0].alt} />
                        :
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/by_window.jpg'} alt="girl by window black and white " />
                }
                <div className="lady-details-div"> 
                    <span>{lady.name} </span><br/>
                    {lady.bio} <br/><br/>
                    {lady.ethnicity} <br/>
                    Size: {lady.size} <br/>
                    Age: {lady.age} <br/>
                    Hair: {lady.hair} <br/>
                    Bust: {lady.bust} <br/>
                    About: {lady.about} <br/>
                </div>
            </div>
        </Link>
    )
}

export default LadyCardHover