import React from 'react'
import { Redirect } from 'react-router-dom'

const RedirectManager = (props) => {
    console.log(props)
    return (
        <Redirect to={`/${props.to}`} />
    )
}

export default RedirectManager