import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import { Helmet } from 'react-helmet'

const ContactPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div id="landing-page-container">
            <Helmet>
                <title>The Viper Room Contact Us</title>
                <meta name="description" content="Address: 945 Fairfield Rd, Yeerongpilly QLD 4105. Phone: (07) 3426 8126. Email: info@theviperroom.com.au" />
            </Helmet>
            <PageHeader />

            <div id="not-found-prompt">
                <h3> Contact Us </h3>
                <div id="visit-div" >
                <div>
                    <div id="visit-info">
                        <a target="blank" href="https://www.google.com/maps/place/945+Fairfield+Rd,+Yeerongpilly+QLD+4105/@-27.5373141,153.008752,17z/data=!3m1!4b1!4m5!3m4!1s0x6b915006252e64f3:0x5782b0d3759daa65!8m2!3d-27.5373189!4d153.0109407" > 
                            <span> <div><i class="fas fa-map-marker-alt" id="i-adj"></i></div> 945 Fairfield Rd, Yeerongpilly QLD 4105 </span>
                        </a> <br/>
                        <a href = "mailto:info@theviperroom.com.au"> <span> <div><i class="fas fa-envelope-square"></i></div> info@theviperroom.com.au </span></a>  <br/> 
                        <a href="tel:07%203426%208126"> <span> <div><i class="fas fa-phone-square"></i></div> (07) 3426 8126 </span> <br/> </a>
                        <a href="https://www.instagram.com/the_viper_room_bne/" target="blank"><span> <div><i class="fab fa-instagram"></i></div> @the_viper_room_bne </span></a>  <br/>
                        <a href="https://twitter.com/theviperroomau" target="blank">  <span> <div><i class="fab fa-twitter"></i></div> @theviperroomau </span> </a> <br/>
                    </div>
                </div>
                <div id="map-display">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3537.8115207404394!2d153.00875201566916!3d-27.537314126176096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b915006252e64f3%3A0x5782b0d3759daa65!2s945%20Fairfield%20Rd%2C%20Yeerongpilly%20QLD%204105!5e0!3m2!1sen!2sau!4v1592705283750!5m2!1sen!2sau"></iframe>
                </div>
            </div>
                
            </div>
            
            <PageFooter />
        </div>
    )
}

export default ContactPage