import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'


const MobileNav = () => {
    
    const [show, setShow] = useState(false)

    const toggleMore = () => {
        setShow(!show)
    } 

    return (
        <div id="nav-bar-phone">
            <Link to="/"> 
                <button 
                    name="home"> 
                    <i class="fas fa-home"></i><br/>
                    <span>Home </span>
                </button> 
            </Link> 
            <Link to="/viper-room-ladies"> 
                <button
                    name="ladies"> 
                    <i class="fas fa-female"></i><br/>
                    <span> Playmates </span>
                </button> 
            </Link> 
            <Link to="/our-services">
                <button
                    name="rates"> 
                    <i class="fas fa-dollar-sign"></i><br/>
                    <span>Rates </span>
                </button>
            </Link>
            <Link to='/schedule'>
                <button> 
                    <i class="far fa-calendar-alt"></i><br/>
                    <span>Schedule </span>
                </button>
            </Link>
                <button
                    onClick={toggleMore} >
                    <i class={!show ? "fas fa-angle-up" : "fas fa-angle-down"}></i> <br/>
                    <span> {!show ? "More" : "Less"} </span>
                </button>

            <div style={show ? null : {display: "none"}} id="more-tab" >
                <Link to="/discretion">
                    <button> 
                        <i class="far fa-smile-wink"></i><br/>
                        <span> Discretion </span>
                    </button>
                </Link>
                <Link to="/tour">
                    <button> 
                        <i class="fas fa-camera"></i><br/>
                        <span> Tour </span>
                    </button>   
                </Link>
                <Link to="/whats-happening">
                    <button> 
                        <i class="fas fa-question-circle"></i><br/>
                        <span> News </span>
                    </button>
                </Link>
                <Link to="/contact">
                    <button> 
                        <i class="fas fa-mobile"></i> <br/>
                        <span> Contact </span>
                    </button>
                </Link>
                <button></button>
            </div>
        </div>
    )
}

export default MobileNav