import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import avatarDefault from '../../images/default-pic.png'
import loadingGif from '../../images/loading.gif'
import LadyForm from '../minor_components/LadyForm.component'
import moment from 'moment'
import { extendMoment } from 'moment-range';

const EmployeesPage = () =>  {

    // ------------------ VERIFICATION ------------------

    const [verified, setVerified] = useState(false)
    const [loading, setLoading] = useState(true)
    
    // api verification if admin
    useEffect(() => {
        axios.get('/api/auth/user')
        .then(response => {
            if (response.status = 200) {
                setVerified(true)
                setLoading(false)
            }
            setLoading(false)
        })
        .catch(err => {
            setLoading(false)
        })
    }, [])

    // --------------------------------------------------
    
    // information input state for creation
    const [ ladyDetails, setLadyDetails ] = useState({
        name: '',
        bio: '',
        ethnicity: '',
        size: '',
        age: '',
        hair: '',
        bust: '',
        about: ''
    })
    
    const [ file, setFile ] = useState(null)
    const [ imageAlt, setImageAlt ] = useState('')
    const [ changeAlt, setChangeAlt ] = useState(null)
    
    // handling viewing of employees
    const [ employees, setEmployees ] = useState([])
    const [ viewing, setViewing ] = useState(null)
    const [ backTo, setBackTo ] = useState(null)
    const [ showUA, setShowUA] = useState(false)
    const [ datePicker, setDates] = useState({
        startDate: null,
        endDate: null
    }) 
    
    // handling general processes
    const [ flash, setFlash ] = useState(null)
    const [ updated, setUpdated ] = useState(false)
    const [ query, setQuery ] = useState('')
    const [ confirm, setConfirm ] = useState(false)
    const [ uploading, setUploading ] = useState(false)
    
    // handle flash messages
    useEffect(() => {
        if(flash && flash.success) {
            setTimeout(() => {
                setFlash(null)
            }, 2000)
        }
    }, [flash])


    // api call getting employee list- [updated] to trigger if needing
    useEffect(() => {
        axios.get('/api/employees')
            .then(response => {
                setEmployees(response.data)
                setUpdated(false)
                if (backTo) {
                    setViewing(backTo)
                    setBackTo(null)
                }
            })
            .catch(err => console.log(err))
    },[updated])

    // api call - creating employees with state saved info
    const handleCreate = () => {
      
        const req = ladyDetails
        console.log(req)
        
        axios.post('/api/employees/create', req)
            .then(response => {
                console.log(response)
                if (response.status === 200) {
                    setFlash({
                        success: true,
                        message: `Employee created: ${ladyDetails.name}`
                    })
                    setUpdated(true)
                } else {
                    setFlash({
                        success: false,
                        message: 'Error creating employee'
                    })
                }
            })
            .catch(err => {
                console.log(err)
            })
    
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setLadyDetails({...ladyDetails, [name]: value})
    }

    // displaying employee list on front end
    const renderEmployeeList = () => {
        let output = []
        employees.map(employee => {
            output.push(
                <div className="employee-card" onClick={() => {

                    // setting update fields if required
                    setViewing(employee)
                    setLadyDetails({
                        name: employee.name,
                        bio: employee.bio,
                        size: employee.size,
                        ethnicity: employee.ethnicity,
                        age: employee.age,
                        hair: employee.hair,
                        bust: employee.bust,
                        about: employee.about
                    })
                }} >
                    {
                        employee.media.length > 0 ?
                            <img src={employee.media[0].url} />
                            :
                            <img src={avatarDefault} />
                    }
                    <div>
                        <span> {employee.name}  </span><br/>
                    </div>
                </div>
            )
        })
        return output
    }

    // api call - On search input updating employee query to display results only
    const handleSearch = (e) => {
        console.log(e.target.value)
        if (e.target.value === '') {
            setUpdated(true)
        } else {
            axios.get(`/api/employees/search/${e.target.value}`)
                .then(response => {
                    setEmployees(response.data)
                })
                .catch(err => console.log(err))
        }
    }

    // api call - updating employee details with state saved info
    const handleUpdate = () => {
        const req = ladyDetails
        console.log(req)

        axios.put(`/api/employees/update/${viewing._id}`, req)
            .then(res => {
                setFlash({
                    success: true,
                    message: `Employee updated: ${ladyDetails.name}`
                })
                setUpdated(true)
            })
            .catch(err => {
                setFlash({
                    success: false,
                    message: 'Error updating employee'
                })
            })
    }
 
    // api call - uploading photo to lady media list
    const uploadPhoto = () => {

        if (file) {
            setUploading(true)
            const formData = new FormData()
            formData.append('image', file[0])    
            formData.append('alt', imageAlt)    
            axios({
                method: 'put',
                url: `/api/employees/upload-photo/${viewing._id}`,
                data: formData,
                headers: {'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                setUploading(false)
                setBackTo(response.data.employee)
                setUpdated(true)
            })
            .catch(err => console.log(err))
        } else {
            setFlash({
                success: false,
                message: "Please select a file"
            })
        }
    }

    // api call - deleting photo from media list 
    const deletePhoto = (key) => {
        setUploading(true)
        axios.delete(`/api/employees/photo/delete/${viewing._id}?key=${key}`)
            .then(response => {
                setUploading(false)
                setBackTo(response.data.employee)
                setUpdated(true)
            })
            .catch(err => console.log(err))
    }

    const editAlt = (photoId) => {
        axios.put(`/api/employees/edit-alt/${viewing._id}`, {
            id: photoId,
            alt: changeAlt[1]
        }).then(response => {
            if(response.status === 200) {
                setChangeAlt(null)
                setBackTo(response.data)
                setUpdated(true)
            } 
        }).catch(err => console.log(err))
    
    }

    // displaying photos attached 
    const renderMedia = () => {
        let output = []
        viewing.media.map(image => {
            output.push(
                <div>
                    <img src={image.url} /><br/>
                    {
                        changeAlt && changeAlt[0] === image._id ?
                            <div>
                                <input type='text' value={changeAlt[1]} onChange={(e) => setChangeAlt([image._id, e.target.value])}/> <span id="alt-edit" onClick={() => editAlt(image._id)}> save </span>  
                            </div>
                            :
                            <span id="alt-edit" onClick={() => setChangeAlt([image._id, image.alt])}> edit alt </span>
                    }
                    <i class="fas fa-times-circle" id="photo-del-btn" onClick={()=>deletePhoto(image.key)} ></i>
                </div>
            )
        })
        return output
    }

    // api call - updating lady status 
    const toggleActive = () => {
        const req = {
            active: !viewing.active
        }
        axios.put(`/api/employees/set-active/${viewing._id}`, req)
            .then(response => {
                setBackTo(response.data)
                setUpdated(true)
            })
            .catch(err => console.log(err))
    }

    // api call - deleting lady
    const deleteLady = () => {
        axios.delete(`/api/employees/delete/${viewing._id}`)
            .then(response => {
                window.location.reload(false)
            })
            .catch(err => console.log(err))
    }
    
    const dateChange = (e) => {
        const { name, value } = e.target
        setDates({...datePicker, [name]: value})
    }

    const setUnavailability = () => {
        let start = null
        let end = null
        let datesArray = []
        if (datePicker.startDate === null) {
            return setFlash({
                success: false,
                message: "Please enter a start date"
            })
        }  
        start = moment(datePicker.startDate)
        if (datePicker.endDate !== null) {
            const range = extendMoment(moment)
            end = moment(datePicker.endDate)
            if(!moment(start).isBefore(end)) {
                return setFlash({
                    success: false,
                    message: "Start date must be BEFORE end date"
                })
            }
            let dateRange = range.range(start, end)
            for (let day of dateRange.by('day')) {
                datesArray.push(day.format("DD/MM/YYYY"))
            }
        } else {
            datesArray.push(start.format("DD/MM/YYYY"))
        }
        const req = {
            dates: datesArray
        }
        axios.put(`/api/employees/set-unavailable/${viewing._id}`, req)
            .then(response => {
                setBackTo(response.data)
                setUpdated(true)
            })
            .catch(err => console.log(err))
    }

    const clearUnavailable = () => {
        axios.put(`/api/employees/clear-unavailable/${viewing._id}`)
            .then(response => {
                setBackTo(response.data)
                setUpdated(true)
            })
            .catch(err => console.log(err))
    }

    const renderUnavailability = () => {
        let output = []
        viewing.unavailability.map(date => {
            // console.log(moment().isSameOrAfter(date))
            output.push(
                <p> {`${date.split('/')[0]}/${date.split('/')[1]}`} </p>
            )
        })
        return output
    }

    if (loading) {
        return (
            <div>
                loading...
            </div>
        )
    }

    if (!verified && !loading) {
        return (
            <Redirect to="/" />
        )
    }

    if (verified) {

        return (
            <div id="employees-page-container">
                <div id="ep-left">
                    <Link to="/dashboard"> <button id="home-btn"> Home </button> </Link>
                    <h3> Ladies </h3><br/>
                    {
                        viewing ?
                            null
                            :
                            <div>
                                <span> <strong>Add New</strong></span><br/><br/>

                                <div>
                                    <LadyForm 
                                        handleChange={handleChange}
                                        handleSubmit={handleCreate}
                                    /> 
                                    {
                                        flash ?
                                            <span id="flash-msg" style={flash.success ? {color: "#1DB954"} : {color: "red"}} > {flash.message} </span>
                                            :
                                            null
                                    }
                                </div>

                            </div>
                    }
                </div>
                {
                    viewing ?
                        <div id="viewing-div">
                            <i class="fas fa-arrow-left" onClick={()=> {
                                setViewing(null)
                            }} ></i>
                            <div>
                                <LadyForm 
                                    handleChange={handleChange}
                                    handleSubmit={handleUpdate}
    
                                    name={ladyDetails.name}
                                    bio={ladyDetails.bio}
                                    ethnicity={ladyDetails.ethnicity}
                                    size={ladyDetails.size}
                                    age={ladyDetails.age}
                                    hair={ladyDetails.hair}
                                    bust={ladyDetails.bust}
                                    about={ladyDetails.about}
                                />
                                {
                                    flash ?
                                        <span id="flash-msg" style={flash.success ? {color: "#1DB954"} : {color: "red"}} > {flash.message} </span>
                                        :
                                        null
                                }
                            </div>
                            <div id="upload-div">
                                <span> Status: </span>
                                <button 
                                    style={viewing.active ? null : {backgroundColor: "red"}} 
                                    onClick={toggleActive}
                                    > 
                                    {viewing.active ? "Active" : "Inactive"} 
                                </button><br/><br/>
                                <div id="unavailability-div">
                                    <span> Unavailability </span> <a onClick={() => setShowUA(!showUA)}> set </a> <a onClick={clearUnavailable}> clear </a> 
                                    {showUA ?
                                        <div id="set-form">
                                            start: <input name="startDate" type="date" onChange={(e)=>dateChange(e)} />
                                            end: <input name="endDate" type="date" onChange={(e)=>dateChange(e)} />
                                            <a onClick={setUnavailability} > add </a>
                                        </div> 
                                        :
                                        null
                                    }
                                    <div id="un-dates-list">
                                        {renderUnavailability()}
                                    </div>
                                </div>
                                <br/>
                                <span> Upload Image </span> <br/>
                                <input type="file" name="image" onChange={(e) => setFile(e.target.files)} /> <br/>
                                <input type="text" placeholder="Enter alt text" className="alt-text-input" onChange={(e) => setImageAlt(e.target.value)} />
                                {uploading ? <img className="loading-gif" src={loadingGif} /> : null }   
                                    <br/>
                                <button onClick={uploadPhoto}> upload </button>
                                <p> Current media </p>
                                <div id="media-list">
                                    {renderMedia()}
                                </div>
                                {
                                    confirm ?
                                        <button id="delete-btn-confirm" onClick={()=>setConfirm(false)}> CANCEL </button>
                                        :
                                        <button id="delete-btn" onClick={()=>setConfirm(true)}> DELETE LADY </button>
                                }
                                { confirm ?  <span id="confirm-delete" onClick={deleteLady} > are you sure? </span> : null}
                            </div>
                        </div>
                        :
                        <div id="employee-list">
                            <h3> Ladies </h3>
                            <input id="search-input" type="text" onChange={(e) => handleSearch(e)} placeholder="Search for employee..." />
                            <div id="list-container">
                                {renderEmployeeList()}
                            </div>
                        </div>
                }
            </div>
        
        )
    }


} 

export default EmployeesPage