import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

const PageHeader = (props) => {

    const [active, setActive] = useState('home')
    const [screen, setScreen] = useState(null)
    const [sticky, setSticky] = useState(false)
    const ref = useRef(null)

    useEffect(() => {
        if(props.page) {
            setActive(props.page)
        }
        setScreen(window.innerWidth)
        if (window.innerWidth > 750) {
            window.addEventListener('scroll', handleScroll)
            return () => {
                window.removeEventListener('scroll', handleScroll);
              };
        }
    },[])


    const handleScroll = () => {
        if (window.pageYOffset > ref.current.offsetTop) {
            setSticky(true)
        } else {
            setSticky(false)
        }
        
    }


    const renderActiveBtn = (btn) => {
        if (btn === active) {
            return ({
                color: "white",
                borderBottom: "3px solid #e227e2"
            })
        }
        return null
    }

    const stickyHeader = () => {
        return {
            position: "fixed",
            top: "0",
            width: "100%",
            textAlign: "center",
            backgroundColor: "black",
            margin: "0",
            padding: "20px 0"
        }
    }

    return (
        <div id="contact-header"> 
            <div id="header-top">
                <Link to="/">
                    <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/the-viper-room.png'} id="viper-logo" alt="The Viper Room logo"/>
                </Link>
                <div id="contact-icon">
                    <a target="blank" href="https://www.google.com/maps/place/945+Fairfield+Rd,+Yeerongpilly+QLD+4105/@-27.5373141,153.008752,17z/data=!3m1!4b1!4m5!3m4!1s0x6b915006252e64f3:0x5782b0d3759daa65!8m2!3d-27.5373189!4d153.0109407" > 
                        <span> <i class="fas fa-map-marker-alt" id="i-adj"></i> 945 Fairfield Rd, Yeerongpilly QLD 4105 </span> 
                    </a><br/>
                    <a href="tel:07%203426%208126">  <span> <i class="fas fa-phone-alt"></i> 07 3426 8126 </span></a><br/>
                    <span> <i class="far fa-clock"></i> Open Hours: 24/7 </span><br/>
                </div>
            </div>

            {
                screen > 750 ? 
                    <div ref={ref} style={sticky ? stickyHeader() : null} id="landing-nav-bar">
                        <Link to="/"> 
                            <button style={renderActiveBtn('home')} > HOME </button> 
                        </Link> 
                        <Link to="/viper-room-ladies"> 
                            <button style={renderActiveBtn('ladies')}> PLAYMATES </button> 
                        </Link> 
                        <Link to="/our-services">
                            <button style={renderActiveBtn('rates')} > RATES </button>
                        </Link>
                        <Link to="/discretion">
                            <button style={renderActiveBtn('discretion')}> DISCRETION </button>
                        </Link>
                        <Link to="/tour">
                            <button style={renderActiveBtn('tour')}> TOUR </button>
                        </Link>
                        <Link to="/schedule"> 
                            <button style={renderActiveBtn('schedule')} > SCHEDULE </button> 
                        </Link> 
                        <Link to="/whats-happening">
                            <button style={renderActiveBtn('blog')} > WHAT'S HAPPENING </button>
                        </Link>
                    </div>
                :
                null
            }

        </div>
    )
}

export default PageHeader