import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import axios from 'axios'
import Moment from 'react-moment'
import loadingGif from '../../images/loading.gif'
import { Helmet } from 'react-helmet'

const BlogSingle = (props) => {

    const [blog, setBlog] = useState(null)
    const [loading, setLoading] = useState(true)
    const [prev, setPrev] = useState(null)
    const [next, setNext] = useState(null)

    // api call getting blog by title
    useEffect(() => {
        window.scrollTo(0, 0)
        const title = props.match.params.title
        axios.get(`/api/blogs/single/${title}`)
            .then(response => {
                setBlog(response.data.current)
                setPrev(response.data.prev)
                setNext(response.data.next)
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [props])

    return (
        <div id="single-blog-container">
            <Helmet>
                <title> The Viper Room - Current News Article </title>
                <meta
                    name="description"
                    content={blog ? `${blog.title}` : 'Read all the latest news coming out from The Viper Room here! About our ladies and more!'}
                />
            </Helmet>
            <PageHeader />
            {
                loading ?
                    <div id="sb-blog-wrapper">
                        <img src={loadingGif} className="loading-gif" />
                    </div>
                    :
                    blog ?
                        <div id="sb-blog-wrapper">
                            <div>
                                <div id="sb-top">
                                    <Link to="/whats-happening"> Back to blogs </Link><br/>
                                    <div id="sb-nav">
                                        {
                                            prev ?
                                                <Link to={`/blog/${prev.title.split(' ').join('-')}`}> 
                                                    <button>
                                                        <i class="fas fa-angle-left"></i><br/>
                                                        <span>previous: "{prev.title.length > 17 ? prev.title.slice(0, 17) + '...' : prev.title}" </span>
                                                    </button>
                                                </Link>
                                                :
                                                null
                                        }
                                        {
                                            next ?
                                                <Link to={`/blog/${next.title.split(' ').join('-')}`}> 
                                                    <button>
                                                        <i class="fas fa-angle-right"></i><br/>
                                                        <span>next: "{next.title.length > 17 ? next.title.slice(0, 17) + '...' : next.title}" </span>
                                                    </button>
                                                </Link>
                                                :
                                                null
                                        }
                                    </div><br/>
                                    <h3> {blog.title} </h3>
                                    <span id="sb-blog-date"> <Moment date={blog.date} format="MMMM DD, YYYY" /> </span> <br/>
                                    <p id="sb-blog-content">
                                        {blog.content}
                                    </p>
                                </div>
                                {
                                    blog.image && blog.image.url ?
                                        <img src={blog.image.url} alt={blog.image.alt} />
                                        :
                                        null
                                }
                            </div>
                        </div>
                        :
                        <div id="sb-blog-wrapper" >
                            <p> Sorry blog doesnt exist </p>
                        </div>

            }
            <PageFooter />
        </div>
    )

}

export default BlogSingle