import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import { Helmet } from 'react-helmet'

const NotFound = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div id="landing-page-container">
             <Helmet>
                <title> The Viper Room - Sorry this page doesnt exist </title>
                <meta
                    name="description"
                    content="This page doesn't exist on theviperroom.com.au, try another one!"
                />
            </Helmet>
            <PageHeader />
            <div id="not-found-prompt">
                <h3> 404 - This page doesn't exist. </h3>

                <p> Try one of these ones ! </p>
            </div>


            <div id="option-bar-1" >
                <Link to="/viper-room-ladies">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/ladies_button.jpg'} alt="service provider with hands on hips in pink" />
                    </div>
                </Link>
                <Link to="/our-services">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/rates-n-services-button.png'} alt="man and woman hugging" />
                    </div>
                </Link>
                <Link to="/schedule"> 
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/schedule_button.png'} alt="Brisbane playmate applying red lipstick" />
                    </div>
                </Link>
                <Link to="/tour">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/rooms_button.jpg'} alt="image of room 1 bed and spa" />
                    </div>
                </Link>
                <Link to="/viper-room-ladies">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/gallery_button.png'} alt="beautiful lady with finger to lips"/>
                    </div>
                </Link>
                <Link to="/contact">
                    <div className="bar-option-card">
                        <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/location_button.png'} alt="Brisbane brothels The Viper Room from outside at night with lights"/>
                    </div>
                </Link>
            </div>
            
            <PageFooter />
        </div>
    )
}

export default NotFound