import React from 'react'

const LadyCard = (props) => {
    
    const lady = props.lady

    return (
        <div className="lady-card" 
            onMouseEnter={props.onHover}
            onClick={props.handleClick}
        >
            {
                lady.media.length > 0 ?
                    <img src={lady.media[0].url} alt={lady.media[0].alt}/>
                    :
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/by_window.jpg'} alt="half naked girl by window" />
            }
            <div className="single-name">
                <span>{lady.name} </span>
            </div>
        </div>
    )
}

export default LadyCard