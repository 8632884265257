import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import avatarDefault from '../../images/default-pic.png'

const daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday','sunday']


const Manager = (props) => {

    // ------------------ VERIFICATION ------------------

    const [verified, setVerified] = useState(false)
    const [initLoad, setInit] = useState(true)

    useEffect(() => {
        axios.get('/api/auth/user')
            .then(response => {
                if (response.status = 200) {
                    setVerified(true)
                    setInit(false)
                }
                setInit(false)
            })
            .catch(err => {
                setInit(false)
            })

    }, [])
    // --------------------------------------------------

    const [week, setWeek] = useState({})
    const [workforce, setWorkForce] = useState([])
    const [bookmarkList, setBookmarkList] = useState([])
    const [updateEmployees, setUpdateEmp ] = useState(false)
    const [updateWeek, setUpdateWeek ] = useState(false)
    const [loading, setLoading] = useState(true)
    const [activeCard, setCard] = useState(null)
    const [displayReserve, setDisplayReserve] = useState(false)
    const [flash, setFlash] = useState(null)

    useEffect(() => {
        if (flash) {
            setTimeout(() => {
                setFlash(null)
            }, 3000)
        }
    }, [flash])

    // api call - getting week information
    useEffect(() => {
        getApiWeek()
    },[updateWeek])

    // api call - updaing employee list
    useEffect(() => {
        getApiEmployees()
    }, [updateEmployees])

    // api call - getting week data by date (called above)
    const getApiWeek = () => {
        const date = props.match.params.date.split('-').join('/')
        axios.get(`/api/weeks/bydate?date=${date}`)
            .then(response => {
                let week = response.data
                if (activeCard) {
                    week[activeCard[0]].shifts[[activeCard[1]]].selected = true
                }
                setWeek(week)
                setLoading(false)
                setUpdateWeek(false)
            })
            .catch(err => console.log(err))
    }

    // api call - getting employee information (called above)
    const getApiEmployees = () => {
        axios.get('/api/employees')
            .then(response => {
                setWorkForce(response.data)
                setUpdateEmp(false)
            })
            .catch(err => console.log(err))
    }

    // api call - getting employees by search query
    const handleSearch = (e) => {
        if (e.target.value === '') {
            setUpdateEmp(true)
        } else {
            axios.get(`/api/employees/search/${e.target.value}`)
                .then(response => {
                    setWorkForce(response.data)
                })
                .catch(err => console.log(err))
        }
    }

    // api call - adding bookmark to lady for reserve shift ref
    const addBookmark = (lady) => {

        if(activeCard) {
            const req = {
                date: week[activeCard[0]].date,
                index: activeCard[1]
            }
    
            axios.put(`/api/employees/add-bookmark/${lady._id}`, req)
                .then(response => {
                    console.log(response.data)
                    let reserveList = bookmarkList
                    reserveList.push(response.data)
                    setBookmarkList(reserveList)
                    setUpdateEmp(true)
                })
                .catch(err => console.log(err))

        }
        return null
    }

    // api call - removing bookmark to lady for reserve shift ref
    const removeBookmark = (lady) => {
        const shift = () => {
            for (let item of bookmarkList) {
                if (item.lady === lady) {
                    return item.shift
                }
            }
        }

        const req = {
            bookmarkId: shift()._id
        }

        axios.put(`/api/employees/remove-bookmark/${lady._id}`, req)
            .then(response => {
                console.log(response)
                let update = bookmarkList
                update.map(bookmark => {
                    if (bookmark.lady.name === response.data.name) {
                        update.splice(update.indexOf(bookmark), 1)
                    }
                })
                setBookmarkList(update)
                setUpdateEmp(true)
            })
            .catch(err => console.log(err))
    }

    // api call - add worker to embedded array of employees in shift
    const addToShift = (e, employee) => {
        const info = prompt('Enter additional shift information if required') 
        // ignore if other buttons inside are pressed
        if(e.target.className.split(' ')[1] === 'fa-star') {
            return null
        }
        
        // activate only when intending to add to shift (activeCard is true)
        if (activeCard) {
            // add shift to employee's shift list
            if (week[activeCard[0]].shifts[activeCard[1]].employees.length >= 9000) {
                setFlash({
                    success: false,
                    message: "Max number of ladies reached (8)"
                })
            }
            const req = {
                weekId: week._id,
                day: activeCard[0],
                date: week[activeCard[0]].date,
                shiftId: week[activeCard[0]].shifts[activeCard[1]]._id,
                from: week[activeCard[0]].shifts[activeCard[1]].from,
                to: week[activeCard[0]].shifts[activeCard[1]].to
            }

            console.log(req)
            axios.put(`/api/employees/add-shift/${employee._id}`, req)
                .then(response => {
                    console.log(response)
                })
                .catch(err => console.log(err))
            
            // add employee to shifts employee list
            const worker = {
                employeeId: employee._id,
                name: employee.name,
                day: activeCard[0],
                shiftIndex: activeCard[1],
                info: info
            }
            axios.put(`/api/weeks/add-employee/${week._id}`, worker)
                .then(response => {
                    console.log(response)
                    setUpdateWeek(true)
                })
                .catch(err => {
                    console.log(err)
                })

        } else {
            return null
        }
    }

    // api call - to remove shift from employee list and employee from shift list
    const removeFromShift = (employee, shift, weekId, dayOfWeek) => {
        // confirm will return a boolean (true / false)
        const confirm = window.confirm("Are you sure you want to delete shift?");
        // then we check if confirm is true, if so we run the update
        if (confirm) {
          axios.put(`/api/weeks/remove-employee/${weekId}`, { employee, shift, weekId, dayOfWeek })
            .then(response => {
              if (response.status === 200) {
                  setUpdateWeek(true)
              }
            })
            .catch(err => console.log(err))
          axios.put(`/api/employees/remove-shift/${employee}`, {shift})
            .then(response => {
                console.log(response)
            })
            .catch(err => console.log(err))
        }
      }

    // helper function to deterine if lady is on reserve list for shift
    const isOnReserveList = (lady) => {
        if (activeCard) {
            for (let shift of lady.bookmarked) {
                if (shift.shiftDate === week[activeCard[0]].date && Number(shift.shiftIndex) === Number(activeCard[1])) {
                    return true
                }
            }
        }
        return false
    }

    // helper function - determines if lady is on shift
    const isOnShift = (lady) => {
        if (activeCard) {
            for (let worker of week[activeCard[0]].shifts[activeCard[1]].employees) {
                if (worker.name === lady.name) {
                    return true
                }
            }
        }
        return false
    }

    // helper function - determines if lady is unavailable for day
    const isUnavailable = (lady) => {
        if (activeCard) {
            const date = week[activeCard[0]].date
            if (lady.unavailability.includes(date)) {
                return true
            }
        }
        return false
    }

    // saving shift information to state for managing process
    const selectCard = (e) => {
        if (e.target.id === "remove-worker") {
            return null
        } else {
            
            // saving day and index of model by target id 
            const day = e.target.id.split('-')[0]
            const index = e.target.id.split('-')[2]
            setCard([day, index])

            // acknowledging selected shift in overall week model in state
            let update = week
            daysOfWeek.map(day => {
                week[day].shifts.map(shift => {
                    shift.selected = false
                })
            })
            update[day].shifts[index].selected = true
            setWeek(update)

            // setting reserve list by ref to lady model
            let reserveList = []
            workforce.map(lady => {
                for (let shift of lady.bookmarked) {
                    if (shift.shiftDate === week[day].date && Number(shift.shiftIndex) === Number(index)) {
                        reserveList.push({lady, shift})
                    }
                }
            })
            setBookmarkList(reserveList)
        }
    }

    // helper function for return lady model by name from overall list
    const getLadyFromList = (name) => {
        for (let lady of workforce) {
            if (lady.name === name) {
                return lady
            }
        }
    }

    // helper function to determine shift card height based on week hoursPerDay
    const shiftBoxHeight = (from, to) => {
        const timeLength = () => {
            if ((to - from) < 0) {
                return (24 - from + to)
            }
            return (to - from)
        }
        const height = (timeLength() / week.hoursPerDay * 80)
        return height
    }


    // displaying employee list on client
    const renderWorkforce = () => {
        let output = []
        workforce.map(employee => {
            if(!isOnShift(employee)) {
                output.push(
                    <button 
                        onClick={(e) => addToShift(e, employee)}
                        style={isUnavailable(employee) ? {color: "red", textDecoration:"line-through", opacity: "0.6"} : null}
                        disabled={isUnavailable(employee) ? true : false}
                    > 
                        {
                            isOnReserveList(employee) ?
                                <i class="fas fa-star" id="bookmarked-star" onClick={()=> removeBookmark(employee)} ></i>
                                :
                                <i class="far fa-star" onClick={()=> addBookmark(employee)} ></i>
                        }
                        {employee.name} 
                    </button>
                )
            }
        })
        return output
    }

    // 3 part rending of shift cards
    const renderShifts = (day) => {
        let output = []
        let names = []

        // saving names for ref down below
        workforce.map(lady => {
            names.push(lady.name)
        })

        // counter for id assigning to e.targets ref'd later
        let counter = 0

        // main shift iteration
        week[day].shifts.map(shift => {

            // determining card height through helper
            const boxHeight = shiftBoxHeight(Number(shift.from.split(':')[0]), Number(shift.to.split(':')[0]))
            
            // 2nd iteration on week's shifts (3) to save ladies on shift
            let onShift = []
            shift.employees.map(employee => {

                // render employee if in state employee list (most up to date)
                if (names.includes(employee.name)) {
                    const lady = getLadyFromList(employee.name)
                    onShift.push(
                        <span className="worker-name" id={`${day}-worker-${counter}`} > 
                            <div id={`${day}-workwrap-${counter}`} src={avatarDefault}>
                                {
                                    lady.media.length > 0 ?
                                        <img id={`${day}-img-${counter}`} src={lady.media[0].url} />
                                        :
                                        <img id={`${day}-img-${counter}`} src={avatarDefault} /> 
                                }
                                {`${employee.name} ${employee.info ? `(${employee.info})` : ''}`} 
                            </div>
                            <i 
                                className="fas fa-times-circle" 
                                id="remove-worker" 
                                onClick={() => removeFromShift(employee.employeeId, shift._id, week._id, day)}
                            ></i>
                        </span>
                    )
                }
            
            })

            // main render array
            output.push(
                <div className="shift-wrapper" key={`${day}-shift-${counter}`}>
                    <div 
                        className="shift-card" 
                        style={
                            shift.selected ?
                                {
                                    border: "3px solid #1DB954",
                                    minHeight:`${boxHeight}vh`
                                }
                                :
                                {
                                    minHeight:`${boxHeight}vh`
                                }
                        }
                        onClick={(e) => selectCard(e)} 
                        id={`${day}-card-${counter}`} 
                    >
                        <span id={`${day}-from-${counter}`} > {shift.from} </span>
                        <div 
                            className="shift-workers" 
                            id={`${day}-workerlist-${counter}`} 
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                fontSize: "0.9em",
                            }}
                        >
                            {onShift}
                        </div>
                        <span id={`${day}-to-${counter}`} > {shift.to} </span>
                    </div>
                </div>
            )
            counter++
            
        })
        return output
    }

    // helper function for rendering date
    const renderDate = (day) => {
        if (week[day]) {
            let date = week[day].date.split('/')
            return `${date[0]}/${date[1]}`
        }
        return null
    }

    // displaying reserve list on toggle
    const renderReserve = () => {
        let output = []
        bookmarkList.map(reserve => {
            output.push(
                <button 
                    onClick={(e) => addToShift(e, reserve.lady)}
                > 
                    {
                        isOnReserveList(reserve.lady) ?
                            <i className="fas fa-star"  id="bookmarked-star" onClick={()=> removeBookmark(reserve.lady)} ></i>
                            :
                            <i className="far fa-star"  onClick={()=> addBookmark(reserve.lady)} ></i>
                    }
                    {reserve.lady.name} 
                </button>
            )
        })
        return output
    }

    if (initLoad) {
        return (
            <div>
                loading...
            </div>
        )
    }

    if (!verified && !initLoad) {
        return (
            <Redirect to="/" />
        )
    }

    if (verified) {

        return (
            <div id="mananger-page-container">
                <div id="workforce-panel">
                    <Link to="/dashboard"> <button className="home-btn"> Home </button> </Link><br/><br/>
                    {
                        displayReserve ?
                            <button id="reserve-btn" onClick={()=>setDisplayReserve(false)} > Main List </button>
                            :
                            <button id="reserve-btn" onClick={()=>setDisplayReserve(true)} > Reserve List ({bookmarkList.length}) </button>
                    }
                    {
                        flash ?
                            <span style={{color: "red", fontSize: "0.7em"}} > {flash.message} </span>
                            :
                            null
                    }
                    <input type="text" placeholder="Search employee" onChange={(e)=> handleSearch(e)} />
                    <div>
                        {
                            displayReserve ?
                                renderReserve()
                                :
                                renderWorkforce()
                        }
                    </div>
                </div>
    
                <div id="week-planner">
    
                    <div className='day-col'>
                        <p onClick={()=> {
                            console.log(week)
                            console.log(activeCard)
                        }}>Monday ({renderDate('monday')}) </p>
                        <div>
                            {!loading ? renderShifts('monday') : null}
                        </div>
                    </div>
    
                    <div className='day-col'>
                        <p>Tuesday ({renderDate('tuesday')}) </p>
                        {!loading ? renderShifts('tuesday') : null}
                    </div>
    
                    <div className='day-col'>
                        <p>Wednesday  ({renderDate('wednesday')})</p>
                        {!loading ? renderShifts('wednesday') : null}
                    </div>
    
                    <div className='day-col'>
                        <p>Thursday ({renderDate('thursday')})</p>
                        {!loading ? renderShifts('thursday') : null}
                    </div>
    
                    <div className='day-col'>
                        <p>Friday ({renderDate('friday')})</p>
                        {!loading ? renderShifts('friday') : null}
                    </div>
    
                    <div className='day-col'>
                        <p>Saturday ({renderDate('saturday')})</p>
                        {!loading ? renderShifts('saturday') : null}
                    </div>
    
                    <div className='day-col'>
                        <p>Sunday ({renderDate('sunday')})</p>
                        {!loading ? renderShifts('sunday') : null}
                    </div>
                </div>
    
            </div>
        )
    }


} 

export default Manager