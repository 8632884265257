import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PageHeader from '../minor_components/PageHeader.component'
import Axios from 'axios'
import PageFooter from '../minor_components/PageFooter.component'
import LadyCardHover from '../minor_components/LadyCardHover.component'
import LadyCard from '../minor_components/LadyCard.component'
import loadingGif from '../../images/loading.gif'
import { Helmet } from 'react-helmet'

const LadiesPage = () => {

    const [list, setList] = useState([])
    const [updateList, promptUpdateList] = useState(false)
    const [hovering, setHovering] = useState(null)
    const [screenSize , setScreenSize] = useState(null)
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        window.scrollTo(0, 0)
        // saving screen size to determine nav display (mobile/pageheader)
        setScreenSize(window.innerWidth)
    }, [])

    // api call - getting active ladies
    useEffect(() => {
        Axios.get('/api/employees/active')
            .then(response => {
                setList(response.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })

    },[updateList])

    // displaying list of ladies
    // screen size determines the render type of lady card 
        //  if mobile - card prompts click for hover effect on desktop (screensize ? 800)
    const renderList = () => {
        let output = []
        list.map(lady => {
            output.push(
                <div>        
                    {
                        hovering === lady.name ?
                            <LadyCardHover 
                                lady={lady} 
                                // offHover={()=>setHovering(null)} 
                            />
                            :
                            screenSize > 800 ?
                                <LadyCard 
                                    lady={lady} 
                                    onHover={() => setHovering(lady.name)} 
                                    // offHover={() => setHovering(null)}
                                />
                                :
                                <LadyCard 
                                    lady={lady} 
                                    handleClick={() => setHovering(lady.name)}
                                />

                    }
                </div>
            )
        })
        return output
    }


    return (
        <div id="ladies-page-container">
            <Helmet>
            <title>The Viper Room Ladies|Playmates of brothel Brisbane</title>
                <meta name="description" content="Our playmates are carefully selected to ensure that only the finest ladies are available for your pleasure. 
                    Ensuring a variety of playmates to cater to all tastes and fantasies. 
                    As the premier brothel Brisbane, we attract the sexiest companions from around the world. Come and experience The Viper Room difference." />
            </Helmet>
            <PageHeader page="ladies"/>
            <div id="landing-banner">
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/paige.jpg'} alt="Brisbane playmate laying on bed in red and in heels" />
                <h1> OUR SEXY <span>PLAY</span>MATES </h1>
            </div>
            <div id="page-details">
                <div className="content-div">
                    <span>Be Tempted...<br/></span>
                    <p>Your Pleasure, Is Our Pleasure</p>
                </div >
                <div className="content-div">
                    Our playmates are carefully selected to ensure that only the finest playmates are available for your pleasure. 
                    Ensuring a variety of playmates to cater to all tastes and fantasies. 
                    As the premier brothel Brisbane we attract the sexiest companions from around the world. 
                </div>
                <div className="content-div">
                    To be a playmate at The Viper Room each companion must be beautiful yes, but also tantalisingly sexy, passionate, energetic with a desire to 
                    please. With over 50 gorgeous playmates working from The Viper Room, there is a playmate to suite everyone, from blondes, to brunettes and redheads. 
                    There only desire is to pleasure and satisfy. Come and experience The Viper Room difference today.
                </div>
            </div>
            <div id="ladies-list" onMouseLeave={()=>setHovering(null)}>
                {
                    loading ?
                        <img className="loading-gif" src={loadingGif} />
                        :
                        list.length > 0 ?
                            renderList()
                            :
                            <p style={{color: "#e227e2", fontWeight: "600", fontSize: "1em"}} > Our ladies are being updated at the moment! Watch our socials to know when they are up again!</p>
                }
            </div>
            <PageFooter />
        </div>
    )
}

export default LadiesPage