import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'

const AdminPage = () => {

    // ------------------ VERIFICATION ------------------
    
    const [verified, setVerified] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get('/api/auth/user')
            .then(response => {
                if (response.status = 200) {
                    setVerified(true)
                    setLoading(false)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    // --------------------------------------------------

    const [weeks, setWeeks ] = useState(null)
    const [updated, setUpdated ] = useState(false)
    const [confirm, setConfirm] = useState(null)


    // called on every info page update
    useEffect(() => {
        // api call to get weeks data
        axios.get('/api/weeks')
            .then(response => {
                setWeeks(response.data)
            })

    }, [updated])

    // api call logout function
    const logout = () => {
        axios.get('/api/auth/logout')
            .then(response => {
                setVerified(false)
            })
            .catch(err => console.log(err))
    }

    // display weeks (construction url query by getting the 'monday' date)
    const renderList = () => {
        let output = []
        weeks.map(week => {
            output.push(
                <div>
                    <Link to={`/week/${week.monday.date.split('/').join('-')}`}> <button > Week starting <br/>{week.monday.date} </button> </Link> 
                    {
                        confirm && week.monday.date === confirm ?
                            <span onClick={()=>setConfirm(false)} > cancel </span>
                            :
                            <i class="fas fa-trash" onClick={()=>setConfirm(week.monday.date)} ></i>
                    }
                    { confirm && week.monday.date === confirm ? <span id="confirm-btn" onClick={deleteWeek}> are you sure ? </span> : null}
                </div>
            )
        })
        return output
    }

    // api call delete week
    const deleteWeek = () => {
        axios.delete(`/api/weeks/delete?date=${confirm}`)
            .then(response => {
                window.location.reload(false)
            })
            .then(err => console.log(err))
    }

    // initial page verification
    if (loading) {
        return (
            <div>
                loading...
            </div>
        )
    }
    // Redirects back to home if not verified && after loading
    if (!verified && !loading) {
        return (
            <Redirect to="/" />
        )
    }
    
    if (verified) {
        return (
            <div id="admin-page-container">
                <h3> <span>Admin Panel</span> <button onClick={logout} >Log out</button> </h3>
                <Link to="/create-week"> <button> Create Week </button> </Link> <br/><br/>
                <Link to="/employees"> <button> Ladies </button> </Link> 
                <Link to="/manage-album"> <button> Albums </button> </Link>
                <Link to="/manage-blog"> <button> Blog </button> </Link>
                <div id="week-list">
                    <h3> Dashboard </h3>
                    <div id="week-list-wrapper">
                        {
                            weeks ?
                                renderList()
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }


} 

export default AdminPage