import React, { useEffect } from 'react'
import PageHeader from '../minor_components/PageHeader.component'
import PageFooter from '../minor_components/PageFooter.component'
import { Helmet } from 'react-helmet'

const DiscretionPage = () => {

    // start from top on page load
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
    return (
        <div id="discretion-page-container">
            <Helmet>
                <title>The Viper Room Discretion</title>
                <meta name="description" content="We pride ourselves in maintaining confidentiality at ALL times and discretion is maintained during and after each and every visit." />
            </Helmet>
            <PageHeader page={'discretion'} />

            <div id="landing-banner">
                <img src={'https://viper-images.s3-ap-southeast-2.amazonaws.com/Bailey.jpg'} alt="discreet lady wearing black robe and purple stockings" />
                <h1> <span>D</span>ISCRETION </h1>
            </div>
            <div id="disc-content">
                We pride ourselves in maintaining confidentiality at ALL times and discretion is maintained during and after each and every visit.
                <br/><br/>
                <span>Concealed Parking</span><br/>
                Our perimeter fence is around 2.4 metres high ensuring that all vehicles and pedestrians cannot be seen from the main road, maintaining absolute privacy.
                <br/><br/>
                <span>Private Rooms</span><br/>
                We offer Private Waiting Rooms for guests to meet the ladies. These rooms can accommodate groups of up to 2 and 4 people at a time.
                <br/><br/>
                <span>Separate entrance & exit</span><br/>
                We have a separate entrance to exit to maintain your privacy from arrival to departure.
                <br/><br/>
                <p>Please <a href="tel:07%203426%208126">phone</a> or <a href="mailto:info@theviperroom.com.au">email</a> for further information.</p>
            </div>

            <div id="customer-reviews">
                <h1> What our clients say about us </h1>
                <div id="review-list">
                    <div id="review-item">
                        "Been to the Viper Room a number if times over the past 12 months and it never disappoints. 
                        The ladies always give the best service and a great time. 
                        I feel I’ve always had my money’s worth at the end of the session. Thanks Viper, see you all soon.."
                        <br/><br/><span>-B.S</span>
                    </div>
                    <div id="review-item">
                        "Amazing service, the ladies are definitely the best I’ve experienced. 
                        Chanel showed me thing I never thought were possible. Highly recommend their service. Left with a smile 🙂" 
                        <br/><br/><span>-N.B</span>
                    </div>
                    <div id="review-item">
                        "Wonderful facilities, a great selection of lovely ladies. Friendly staff. Great place to visit." 
                        <br/><br/><span>-G.S</span>
                    </div>
                </div>
            </div>
                
            <PageFooter />
        </div>
    )
}

export default DiscretionPage