import React, { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import axios from 'axios'
import loadingGif from '../../images/loading.gif'

const AlbumManage = () =>  {

    // ------------------ VERIFICATION ------------------

    const [verified, setVerified] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get('/api/auth/user')
            .then(response => {
                if (response.status = 200) {
                    setVerified(true)
                    setLoading(false)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            })
    }, [])
    // --------------------------------------------------

    const [uploading, setUploading] = useState(false)
    const [file, setFile] = useState(null)
    const [updated, setUpdated ] = useState(false)
    const [albums, setAlbums] = useState([])
    const [altText, setAlt] = useState('')
    const [name, setName] = useState('')
    const [active, setActive] = useState(null)
    const [changeAlt, setChangeAlt] = useState(null)


    // api call for album data on required updates
    useEffect(() => {
        axios.get('/api/albums')
            .then(response => {
                setAlbums(response.data)
                setUpdated(false)
            })
            .catch(err => console.log(err))
        if(active) {
            getAlbumData(active._id)
        }
    }, [updated])

    const createAlbum = () => {
        axios.post('/api/albums/create', { name })
            .then(response => {
                setUpdated(true)
            })
            .catch(err => console.log(err))
    }

    // api call to upload photo to s3
    const uploadPhoto = () => {
        setUploading(true)
        const formData = new FormData()
        formData.append('image', file[0])    
        formData.append('alt', altText)
        formData.append('album', active._id)
        axios({
            method: 'put',
            url: '/api/albums/upload',
            data: formData,
            headers: {'Content-Type': 'multipart/form-data' }
        })
        .then(response => {
            setUploading(false)
            setUpdated(true)
            setFile(null)
            setAlt('')
        })
        .catch(err => console.log(err))
    }

    // api call to delete photo from s3 (using key)
    const deletePhoto = (key) => {
        setUploading(true)
        axios.delete(`/api/albums/delete-photo/${active._id}?key=${key}`)
            .then(response => {
                setUploading(false)
                setUpdated(true)
            })
            .catch(err => console.log(err))
    }
    
    const getAlbumData = (id) => {
        axios.get(`/api/albums/single/${id}`)
            .then(response => {
                setActive(response.data)
            })
            .catch(err => console.log(err))
    }

    const renderAlbumList = () => {
        let output = []
        albums.map(album => {
            output.push(
                <button onClick={() => {
                    getAlbumData(album._id)
                }} >
                    {album.name}
                </button>
            )
        })
        return output
    }

    const editAlt = (id) => {
        if (changeAlt) {
            axios.put(`/api/albums/edit-alt/${active._id}`, {
                photo: id,
                updateToAlt: changeAlt[1]
            }).then(response => {
                if(response.status === 200) {
                    setChangeAlt(null)
                }
            }).catch(err => console.log(err))
        } else {
            setChangeAlt('')
        }
    }

    // display album (photo)
    const renderPhotos = () => {
        let output = []
        active.photos.map(image => {
            output.push(
                <div className="photo-wrapper">
                    <img src={image.url} />
                    {
                        changeAlt && changeAlt[0] === image._id ?
                            <div>
                                <input type='text' value={changeAlt[1]} onChange={(e) => setChangeAlt([image._id, e.target.value])}/> <span id="alt-edit" onClick={() => editAlt(image._id)}> save </span>  
                            </div>
                            :
                            <span id="alt-edit" onClick={() => setChangeAlt([image._id, image.alt])}> edit alt </span>
                    }
                    <i class="fas fa-times-circle" id="photo-del-btn" onClick={()=>deletePhoto(image.key)} ></i>
                </div>
            )
        })
        return output
    }

    // initial page verification
    if (loading) {
        return (
            <div>
                loading...
            </div>
        )
    }
    // Redirects back to home if not verified && after loading
    if (!verified && !loading) {
        return (
            <Redirect to="/" />
        )
    }

    if (verified) {

        return (
            <div id="employees-page-container">
                <div id="album-mananger">
                    <div id="ab-left" >
                        <Link to="/dashboard"> <button id="home-btn"> Home </button> </Link>
                        <h3> Album </h3>
                        <span> Create Album </span>
                        <div className="form">
                            <span> Name </span><br/>
                            <input type="text" onChange={(e) => setName(e.target.value)} /> <br/>
                            <button onClick={createAlbum}> create </button>
                        </div>
                        <div id="ab-list">
                            {renderAlbumList()}
                        </div>
                    </div>
                    {
                        active ?
                            <div id="ab-right" >
                                <h3> {active.name} </h3>
                                <div id="upload-div">
                                    <span> Upload Image </span> <br/>
                                    <input type="file" name="image" onChange={(e) => setFile(e.target.files)} /> {uploading ? <img className="loading-gif" src={loadingGif} /> : null }   <br/>
                                </div>
                                <input 
                                    type="text" 
                                    placeholder="Enter alt text" 
                                    className="alt-text-input" 
                                    onChange={(e) => setAlt(e.target.value)}
                                /><br/>
                                <button onClick={uploadPhoto}> upload </button>
                                <div id="album-list">
                                    {renderPhotos()}
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        
        )
    }


} 

export default AlbumManage